import { z } from "zod";
export var HeartSoundEnum = z.enum([
    "Normal",
    "AorticRegurgitation",
    "AorticStenosis",
    "FixedSplittingSecondHeart",
    "FourthHeartSound",
    "InnocentMurmur",
    "MildSystolicClick",
    "MiralRegurgitation",
    "MiralStenosis",
    "MitralValveLeafletProlapse",
    "SplittingSecondHeart",
    "ThirdHeartSound",
]);
export var CirculationSchema = z.object({
    capillaryRefillSeconds: z.number().describe("seconds"),
    heartSound: HeartSoundEnum,
});
export var HeartSound = HeartSoundEnum.enum;
