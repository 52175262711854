import { z } from "zod";
import { InjuriesStateSchema } from "../PatientState";
import { BloodPressureSchema } from "../components/BloodPressure";
import { BreathingSoundSchema, WorkOfBreathingSchema } from "../components/Breathing";
import { CirculationSchema } from "../components/Circulation";
import { DisabilitySchema } from "../components/Disability";
import { SkinSchema, ExposureSchema } from "../components/Skin";
import { VitalsSchema } from "../components/Vitals";
import { BloodGasSchema } from "../components/BloodGas";
// Schema dictionary for looking up schemas by name
export var SchemaDictionary = {
    BloodPressure: BloodPressureSchema,
    VitalsState: VitalsSchema,
    BreathingSound: BreathingSoundSchema,
    WorkOfBreathing: WorkOfBreathingSchema,
    Circulation: CirculationSchema,
    Disability: DisabilitySchema,
    Skin: SkinSchema,
    Exposure: ExposureSchema,
    BloodGas: BloodGasSchema,
    Injuries: InjuriesStateSchema,
};
// Mapping of patient state keys to their schemas
export var SchemaMapping = {
    vitals: VitalsSchema,
    breathingSounds: BreathingSoundSchema,
    workOfBreathing: WorkOfBreathingSchema,
    circulation: CirculationSchema,
    disability: DisabilitySchema,
    skin: SkinSchema,
    exposure: ExposureSchema,
    bloodGas: BloodGasSchema,
    injuries: InjuriesStateSchema,
};
// Helper function to get field description from a Zod schema
function getFieldDescription(schema) {
    if (schema instanceof z.ZodString ||
        schema instanceof z.ZodNumber ||
        schema instanceof z.ZodBoolean) {
        return schema.description;
    }
    return undefined;
}
// Helper function to get enum options from a Zod schema
function getEnumOptions(schema) {
    if (schema instanceof z.ZodEnum) {
        return Array.from(schema.options);
    }
    return undefined;
}
function GetAnnotatedSection(sectionName, sectionValues, schema, level) {
    if (level === void 0) { level = 0; }
    console.warn("This function hasn't been tested since the move of component definitions to Zod");
    var result = "";
    if (level === 0) {
        result = "# ".concat(sectionName, "\n");
    }
    if (!sectionValues || typeof sectionValues !== 'object') {
        return result;
    }
    var shape = schema instanceof z.ZodObject ? schema.shape : {};
    for (var key in sectionValues) {
        var headerLine = "**".concat(key, "**:\n");
        var dataLines = "";
        var value = sectionValues[key];
        var fieldSchema = shape[key];
        if (value !== undefined && value !== null) {
            if (typeof value === 'object') {
                dataLines += GetAnnotatedSection(key, value, fieldSchema, level + 1) + "\n";
            }
            else {
                dataLines += " - **Current**: ".concat(value, "\n");
            }
        }
        if (fieldSchema) {
            var description = getFieldDescription(fieldSchema);
            var options = getEnumOptions(fieldSchema);
            if (description) {
                dataLines += " - **Unit/Description**: ".concat(description, "\n");
            }
            if (options) {
                dataLines += " - **Options**: ".concat(options.join(", "), "\n");
            }
        }
        if (dataLines.length > 0) {
            result += headerLine + dataLines;
        }
    }
    result = result.trim();
    var indent = "  ".repeat(level);
    return result.split("\n").map(function (line) { return indent + line; }).join("\n");
}
export function GetAnnotatedPatientState(patientState) {
    var result = "";
    for (var key in patientState) {
        var typedKey = key;
        var schema = SchemaMapping[typedKey];
        var values = patientState[typedKey];
        var sectionText = GetAnnotatedSection(key, values, schema);
        result += sectionText + "\n\n";
    }
    return result;
}
export function GetAllMetadataMarkdown() {
    var result = "";
    for (var _i = 0, _a = Object.entries(SchemaDictionary); _i < _a.length; _i++) {
        var _b = _a[_i], typeName = _b[0], schema = _b[1];
        result += GetSchemaMarkdown(typeName, schema) + "\n";
    }
    return result;
}
function GetSchemaMarkdown(typeName, schema, prefix) {
    if (prefix === void 0) { prefix = ''; }
    var result = "# ".concat(typeName, "\n");
    if (!(schema instanceof z.ZodObject)) {
        return result;
    }
    var shape = schema.shape;
    for (var _i = 0, _a = Object.entries(shape); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], fieldSchema = _b[1];
        var headerLine = "**".concat(prefix).concat(key, "**:\n");
        var dataLines = "";
        var description = getFieldDescription(fieldSchema);
        var options = getEnumOptions(fieldSchema);
        if (description) {
            dataLines += " - **Unit/Description**: ".concat(description, "\n");
        }
        if (options) {
            dataLines += " - **Options**: ".concat(options.join(", "), "\n");
        }
        if (dataLines.length > 0) {
            result += headerLine + dataLines + "\n";
        }
    }
    return result;
}
export function GetAnnotatedPatientJson(patientState) {
    var result = "";
    for (var key in patientState) {
        var typedKey = key;
        var schema = SchemaMapping[typedKey];
        var values = patientState[typedKey];
        if (typeof values === "object" && Object.keys(values).length === 0) {
            continue;
        }
        var sectionText = GetAnnotatedJson(key, values, schema);
        result += sectionText + "\n\n";
    }
    result = result.trim().split("\n").map(function (line) { return "  " + line; }).join("\n");
    return "{\n".concat(result, "\n}");
}
function GetAnnotatedJson(sectionName, sectionValues, schema, level) {
    if (level === void 0) { level = 1; }
    var result = "\"".concat(sectionName, "\":\n{\n");
    var sectionContent = "";
    if (!sectionValues || typeof sectionValues !== 'object') {
        return result + "},";
    }
    var shape = schema instanceof z.ZodObject ? schema.shape : {};
    for (var key in sectionValues) {
        var headerLine = "\"".concat(key, "\": ");
        var value = sectionValues[key];
        var fieldSchema = shape[key];
        var dataLines = "";
        if (value !== undefined && value !== null) {
            if (typeof value === 'object') {
                dataLines += GetAnnotatedJson(key, value, fieldSchema, level + 1);
            }
            else {
                dataLines += "".concat(headerLine).concat(JSON.stringify(value), ",");
            }
        }
        if (fieldSchema) {
            var comments = [];
            var description = getFieldDescription(fieldSchema);
            var options = getEnumOptions(fieldSchema);
            if (description) {
                comments.push(description);
            }
            if (options) {
                comments.push("Options: [".concat(options.join(", "), "]"));
            }
            if (comments.length > 0) {
                dataLines += " // ".concat(comments.join(" - "));
            }
        }
        if (dataLines.length > 0) {
            sectionContent += dataLines + "\n";
        }
    }
    var indent = "  ";
    sectionContent = sectionContent.trim().split("\n").map(function (line) { return indent + line; }).join("\n");
    result += sectionContent + "\n},";
    return result;
}
