var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useCinemedicSupervisorDataContext } from "../Data/CinemedicSupervisorDataContext";
import { motion } from "framer-motion";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
var HybridControlButton = function (_a) {
    var label = _a.label, onClicked = _a.onClicked, isActive = _a.isActive, disabled = _a.disabled;
    return (_jsxs("button", __assign({ onClick: onClicked, className: " relative rounded px-4 py-2 border text-sm flex items-center justify-between shadow overflow-hidden\n                active:shadow-inner disabled:pointer-events-none disabled:opacity-50\n                ".concat(isActive ? "bg-green-100" : "bg-gray-100", "\n                "), disabled: disabled }, { children: [_jsx("span", { children: label }), _jsx("div", __assign({ className: "text-xs" }, { children: isActive ? (_jsx("span", __assign({ className: "text-green-500" }, { children: "Active" }))) : (_jsx("span", __assign({ className: "text-red-500" }, { children: "Inactive" }))) }))] })));
};
var HybridControlSection = function () {
    var _a, _b, _c, _d;
    var _e = React.useState(false), isExpanded = _e[0], setIsExpanded = _e[1];
    var _f = useCinemedicSupervisorDataContext(), cinemedicSessionData = _f.cinemedicSessionData, updateHybridControlData = _f.updateHybridControlData;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    ;
    var toggleLeftArm = function () {
        updateHybridControlData(function (oldData) { return (__assign(__assign({}, oldData), { leftArm: !oldData.leftArm })); });
    };
    var toggleRightArm = function () {
        updateHybridControlData(function (oldData) { return (__assign(__assign({}, oldData), { rightArm: !oldData.rightArm })); });
    };
    var rightArmInjured = ((_a = virtualPatientState.medicalState.injuries) === null || _a === void 0 ? void 0 : _a.rightArm) || false;
    var leftArmInjured = ((_b = virtualPatientState.medicalState.injuries) === null || _b === void 0 ? void 0 : _b.leftArm) || false;
    return (_jsxs("section", __assign({ className: "bg-white shadow rounded-lg p-4" }, { children: [_jsxs("div", __assign({ className: "flex items-center justify-between cursor-pointer", onClick: function () { return setIsExpanded(!isExpanded); } }, { children: [_jsx("h2", __assign({ className: "text-base font-semibold text-gray-800" }, { children: "Hybrid Simulation" })), _jsx(motion.div, __assign({ animate: { rotate: isExpanded ? 180 : 0 }, transition: { duration: 0.2 } }, { children: _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor", className: "w-5 h-5" }, { children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M19 9l-7 7-7-7" }) })) }))] })), _jsx(motion.div, __assign({ initial: { height: 0, overflow: "hidden" }, animate: { height: isExpanded ? "auto" : 0 }, transition: { duration: 0.3 } }, { children: _jsxs("div", __assign({ className: "grid grid-cols-2 gap-3 mt-2" }, { children: [_jsx(HybridControlButton, { label: "Left Arm ".concat(leftArmInjured ? "(Injured)" : ""), onClicked: toggleLeftArm, isActive: ((_c = cinemedicSessionData === null || cinemedicSessionData === void 0 ? void 0 : cinemedicSessionData.hybridControlData) === null || _c === void 0 ? void 0 : _c.leftArm) || false, disabled: leftArmInjured }), _jsx(HybridControlButton, { label: "Right Arm ".concat(rightArmInjured ? "(Injured)" : ""), onClicked: toggleRightArm, isActive: ((_d = cinemedicSessionData === null || cinemedicSessionData === void 0 ? void 0 : cinemedicSessionData.hybridControlData) === null || _d === void 0 ? void 0 : _d.rightArm) || false, disabled: rightArmInjured })] })) }))] })));
};
export default HybridControlSection;
