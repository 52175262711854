import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
import { useBeepLoop } from "./useBeepLoop";
import { MonitorSection, useMonitorSettingsStore } from "./useMonitorSettingsStore";
import { useMonitorAlarms } from "./useMonitorAlarms";
export function useMonitorAudio() {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var _a = useMonitorSettingsStore(), monitorSettings = _a.monitorSettings, alarmsMutedFor = _a.alarmsMutedFor, connectionAlertsPrimedFor = _a.connectionAlertsPrimedFor;
    var _b = useMonitorAlarms(), isHeartRateOutsideThreshold = _b.isHeartRateOutsideThreshold, isSPo2OutsideThreshold = _b.isSPo2OutsideThreshold, isRROutsideThreshold = _b.isRROutsideThreshold;
    var heartRate = virtualPatientState.medicalState.vitals.heartRate;
    var ecgConnected = virtualPatientState.deviceStatus.ecg;
    var shouldPlayNormal = !monitorSettings.hr.muteNormal && ecgConnected;
    var normalBeepConfig = {
        frequency: 1250,
        duration: 100,
        type: "sine",
        volume: 0.3,
        getInterval: function () {
            var hr = heartRate || 60;
            return 60000 / hr;
        },
    };
    useBeepLoop(shouldPlayNormal, normalBeepConfig);
    var hrAlarm = !alarmsMutedFor.includes(MonitorSection.HR) && isHeartRateOutsideThreshold;
    var spo2Alarm = !alarmsMutedFor.includes(MonitorSection.SPO2) && isSPo2OutsideThreshold;
    var rrAlarm = !alarmsMutedFor.includes(MonitorSection.RR) && isRROutsideThreshold;
    var shouldPlayAlarm = hrAlarm || spo2Alarm || rrAlarm;
    var thresholdConfig = {
        frequency: 500,
        duration: 1000,
        type: "square",
        volume: 1,
        getInterval: function () {
            var beepLen = 1000;
            var gap = 1500;
            return beepLen + gap;
        },
    };
    useBeepLoop(shouldPlayAlarm, thresholdConfig);
    var spo2Connected = virtualPatientState.deviceStatus.pulseOximeterLeftFoot;
    var ecgConnectionAlert = connectionAlertsPrimedFor.ecg && !ecgConnected;
    var spo2ConnectionAlert = connectionAlertsPrimedFor.spo2 && !spo2Connected;
    var shouldPlayConnectionAlert = ecgConnectionAlert || spo2ConnectionAlert;
    var signalLossAlertConfig = {
        frequency: 750,
        duration: 500,
        type: "triangle",
        volume: 0.6,
        getInterval: function () {
            var beepLen = 1500;
            var gap = 1000;
            return beepLen + gap;
        }
    };
    useBeepLoop(shouldPlayConnectionAlert, signalLossAlertConfig);
    return {};
}
