var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useEffect, useContext } from 'react';
import { app, firestore as db } from '../../firebase-config';
import { doc, collection, onSnapshot, updateDoc, serverTimestamp, addDoc, query, where, Timestamp, setDoc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { QRCodeSVG } from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
var functions = getFunctions(app);
var server_createSession = function (sessionId) {
    return httpsCallable(functions, 'createSession')({ sessionId: sessionId });
};
var SessionContext = createContext(undefined);
export var SessionProvider = function (_a) {
    var children = _a.children;
    // const auth = getAuth();
    var location = useLocation();
    var queryParams = new URLSearchParams(location.search);
    var sessionIdFromURL = queryParams.get('sessionId');
    var _b = useState(null), firestoreSessionObject = _b[0], setFirestoreSessionObject = _b[1];
    var _c = useState(sessionIdFromURL), sessionId = _c[0], setSessionId = _c[1];
    var messageCallbacks = React.useRef([]);
    var clientId = React.useState(uuidv4())[0];
    var clientIdRef = React.useRef(clientId);
    var firestoreSessionObjectRef = React.useRef(firestoreSessionObject);
    useEffect(function () {
        firestoreSessionObjectRef.current = firestoreSessionObject;
    }, [firestoreSessionObject]);
    var subscribeToMessages = React.useCallback(function (callback) {
        messageCallbacks.current.push(callback);
        // Return an unregister function
        return function () {
            messageCallbacks.current = messageCallbacks.current.filter(function (cb) { return cb !== callback; });
        };
    }, []);
    useEffect(function () {
        if (!sessionId) {
            return;
        }
        function connect(sessionId) {
            return __awaiter(this, void 0, void 0, function () {
                var sessionDocRef, syncSessionData, listenToMessages;
                var _this = this;
                return __generator(this, function (_a) {
                    sessionDocRef = doc(collection(db, 'sessions'), sessionId);
                    syncSessionData = function () { return __awaiter(_this, void 0, void 0, function () {
                        var unsubscribe;
                        return __generator(this, function (_a) {
                            unsubscribe = onSnapshot(sessionDocRef, function (doc) {
                                if (doc.exists() && doc.data().lastActivity != null) {
                                    if (doc.data().lastActivity.writtenBy !== clientIdRef.current || !firestoreSessionObjectRef.current) {
                                        console.log("Synced session data", doc.data());
                                        setFirestoreSessionObject(doc.data());
                                    }
                                }
                            });
                            return [2 /*return*/, function () {
                                    unsubscribe();
                                }];
                        });
                    }); };
                    listenToMessages = function () { return __awaiter(_this, void 0, void 0, function () {
                        var sessionMessagesRef, now, messagesQuery, unsubscribe;
                        return __generator(this, function (_a) {
                            sessionMessagesRef = collection(sessionDocRef, 'messages');
                            now = Timestamp.now();
                            messagesQuery = query(sessionMessagesRef, where('createdAt', '>', now));
                            unsubscribe = onSnapshot(messagesQuery, function (snapshot) {
                                snapshot.docChanges().forEach(function (change) {
                                    if (change.type === 'added') {
                                        var newMessage_1 = change.doc.data();
                                        console.log("New message:", newMessage_1);
                                        messageCallbacks.current.forEach(function (callback) { return callback(newMessage_1); });
                                    }
                                });
                            });
                            return [2 /*return*/, function () {
                                    unsubscribe();
                                }];
                        });
                    }); };
                    syncSessionData();
                    listenToMessages();
                    return [2 /*return*/];
                });
            });
        }
        connect(sessionId);
    }, [sessionId]);
    var tryLoadExistingSession = React.useCallback(function (sessionId) { return __awaiter(void 0, void 0, void 0, function () {
        var sessionDocRef, docSnap, exists;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    sessionDocRef = doc(collection(db, 'sessions'), sessionId);
                    return [4 /*yield*/, getDoc(sessionDocRef)];
                case 1:
                    docSnap = _a.sent();
                    exists = docSnap.exists();
                    if (exists) {
                        setFirestoreSessionObject(docSnap.data());
                    }
                    return [2 /*return*/, exists];
            }
        });
    }); }, [setFirestoreSessionObject]);
    var writeDataToSession = React.useCallback(function (sessionData) { return __awaiter(void 0, void 0, void 0, function () {
        var lastActivity, updatedData, docRef, docHistoryRef;
        return __generator(this, function (_a) {
            lastActivity = { timestamp: serverTimestamp(), writtenBy: clientIdRef.current };
            updatedData = { data: sessionData, lastActivity: lastActivity };
            setFirestoreSessionObject(updatedData);
            if (sessionId) {
                docRef = doc(collection(db, 'sessions'), sessionId);
                updateDoc(docRef, updatedData);
                docHistoryRef = doc(collection(db, 'sessions', sessionId, "history"), new Date().toUTCString());
                setDoc(docHistoryRef, updatedData);
                console.log("Wrote to session", sessionId, updatedData);
            }
            else {
                console.error("Can't write to session: Session ID is not set");
            }
            return [2 /*return*/];
        });
    }); }, [sessionId, setFirestoreSessionObject]);
    var sendMessage = React.useCallback(function (type, data) { return __awaiter(void 0, void 0, void 0, function () {
        var messageData, messagesCollectionRef;
        return __generator(this, function (_a) {
            if (!sessionId) {
                throw new Error("Session ID is not set");
            }
            messageData = {
                type: type,
                data: data,
                createdAt: serverTimestamp()
            };
            messagesCollectionRef = collection(db, 'sessions', sessionId, 'messages');
            addDoc(messagesCollectionRef, messageData);
            return [2 /*return*/];
        });
    }); }, [sessionId]);
    var createSession = React.useCallback(function (staticSessionId) { return __awaiter(void 0, void 0, void 0, function () {
        var existingSession, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (sessionId) {
                        return [2 /*return*/];
                    }
                    if (!staticSessionId) return [3 /*break*/, 2];
                    return [4 /*yield*/, tryLoadExistingSession(staticSessionId)];
                case 1:
                    existingSession = _a.sent();
                    if (existingSession) {
                        setSessionId(staticSessionId);
                        console.log("Loaded existing session", staticSessionId);
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2: return [4 /*yield*/, server_createSession(staticSessionId || undefined)];
                case 3:
                    result = _a.sent();
                    if (result.data && result.data.success) {
                        setSessionId(result.data.sessionId);
                        console.log("Created new session:", result.data.sessionId);
                    }
                    else {
                        console.error("Failed to create session:", result);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [sessionId, tryLoadExistingSession]);
    var sessionQRCode = function (path) {
        var url = window.location.origin;
        var sessionControllerURL = "".concat(url, "/").concat(path, "?sessionId=").concat(sessionId);
        return (_jsx("a", __assign({ href: sessionControllerURL, target: '_blank', rel: "noreferrer", className: 'w-full h-full' }, { children: _jsx(QRCodeSVG, { value: sessionControllerURL, bgColor: '#00000000', fgColor: '#ffffff', className: 'h-full w-full', size: 128, level: 'L' }) })));
    };
    return (_jsx(SessionContext.Provider, __assign({ value: {
            rawSessionData: firestoreSessionObject,
            sessionId: sessionId,
            createSession: createSession,
            writeRawDataToSession: writeDataToSession,
            sessionQRCode: sessionQRCode,
            sendMessage: sendMessage,
            subscribeToMessages: subscribeToMessages,
            doesSessionExist: tryLoadExistingSession
        } }, { children: children })));
};
export var useSession = function () {
    var context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};
