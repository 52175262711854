var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from "framer-motion";
import React from "react";
import { IoIosArrowUp } from "react-icons/io";
var EditableSectionCard = function (_a) {
    var title = _a.title, modified = _a.modified, children = _a.children;
    var _b = React.useState(true), isExpanded = _b[0], setIsExpanded = _b[1];
    return (_jsxs("section", __assign({ className: "bg-white shadow rounded-lg p-4" }, { children: [_jsxs("div", __assign({ className: "flex items-center justify-between cursor-pointer", onClick: function () { return setIsExpanded(!isExpanded); } }, { children: [_jsxs("h2", __assign({ className: "text-base font-semibold text-gray-800 space-x-1" }, { children: [_jsx("span", { children: title }), modified && _jsx("span", __assign({ className: "text-blue-500" }, { children: " (modified)" }))] })), _jsx(motion.div, __assign({ animate: { rotate: isExpanded ? 0 : 180 }, transition: { duration: 0.2 } }, { children: _jsx(IoIosArrowUp, { className: "text-gray-800 w-8 h-8" }) }))] })), _jsx(motion.div, __assign({ initial: { height: 0, overflow: "hidden" }, animate: { height: isExpanded ? "auto" : 0 }, transition: { duration: 0.3 } }, { children: _jsx("div", __assign({ className: "border-t border-gray-200 mt-2" }, { children: children })) }))] })));
};
export default EditableSectionCard;
