var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
// import { useModifiedValues } from "./ControllerUtils";
import SimpleInputControl from "./InputComponents/SimpleInputControl";
import AnimatedSymptomValueBox from "./AnimatedSymptomValueBox";
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
var labelMapping = {
    cyanosisBody: "Cyanosis (Body)",
    cyanosisFace: "Cyanosis (Face)",
    faceRedness: "Face Redness",
    liverEnlargement: "Liver Enlargement",
    mottled: "Mottling",
    paleness: "Paleness",
    sweaty: "Sweating",
    itching: "Itching",
};
export function useSkinState() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setSkinValue(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.skin[key] = value;
        });
    }
    return { setSkinValue: setSkinValue };
}
var SkinController = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var setSkinValue = useSkinState().setSkinValue;
    // const modifiedValues = useModifiedValues<SkinState>("skin");
    var allKeys = Object.keys(virtualPatientState.medicalState.skin);
    return (_jsx(_Fragment, { children: allKeys.map(function (key, id) {
            var value = virtualPatientState.medicalState.skin[key];
            return (_jsx(AnimatedSymptomValueBox, __assign({ value: value }, { children: _jsx(SimpleInputControl, { label: labelMapping[key], value: value, onChange: function (newValue) { setSkinValue(key, newValue); } }) }), id));
        }) }));
};
export default SkinController;
