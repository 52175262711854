import { z } from "zod";
export var PostureEnum = z.enum(["Normal", "Decerebrate"]);
export var ActivityStateEnum = z.enum([
    "Calm",
    "Drowsy",
    "Unconscious",
    "Agitated",
    "Crying",
    "Seizing",
]);
export var DisabilitySchema = z.object({
    activity: ActivityStateEnum,
    avpu: z.enum(["A", "V", "P", "U"]),
    posture: PostureEnum,
    leftPupilSize: z.number().describe("mm"),
    leftPupilSluggishness: z.number().describe("0 - 1, where 0 is normal response time, 1 is very slow"),
    rightPupilSize: z.number().describe("mm"),
    rightPupilSluggishness: z.number().describe("0 - 1, where 0 is normal response time, 1 is very slow"),
    awakeness: z.number().describe("0 - 1, where 0 is fully unconscious, 0.25 is light sleep, 0.75 is drowsy, 1 is fully awake"),
});
export var DefaultDisabilityState = {
    activity: "Calm",
    avpu: "A",
    posture: "Normal",
    leftPupilSize: 0,
    leftPupilSluggishness: 0,
    rightPupilSize: 0,
    rightPupilSluggishness: 0,
    awakeness: 1,
};
export var ActivityState = ActivityStateEnum.enum;
export var Posture = PostureEnum.enum;
