var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Transition } from '@headlessui/react';
import UserProfileComponent from '../../components/Reuseable/UserProfileComponent';
import { useAppContext } from '../../AppContext';
import { FaUser, FaX } from "react-icons/fa6";
import { AuthService } from '../../components/Database/Authentication';
import { PatientType } from 'mirno-shared/src/PatientState';
import { ClipLoader } from 'react-spinners';
import ShareComponent from './ShareComponent';
import VirtualPatientStateStorage from '../../components/Database/VirtualPatientStateStorage';
import { useVirtualPatientStore } from '../../storage/VirtualPatientStore';
var SaveModalContent = function (_a) {
    var onClose = _a.onClose, onSave = _a.onSave, saving = _a.saving, savedPresetId = _a.savedPresetId;
    var _b = useVirtualPatientStore(), virtualPatientState = _b.virtualPatientState, updateVirtualPatientState = _b.updateVirtualPatientState;
    function setName(name) {
        updateVirtualPatientState(function (state) {
            state.name = name;
        });
    }
    // function setDescription(description: string) {
    //   // updateVirtualPatientState((state) => {
    //   //   state.description = description;
    //   // })
    // }
    function inputSection() {
        return (_jsxs(_Fragment, { children: [_jsx("h2", __assign({ className: "text-xl font-bold mb-4" }, { children: "Virtual Patient Information" })), _jsxs("div", __assign({ className: "mb-4" }, { children: [_jsx("label", __assign({ className: "block text-gray-700 mb-2", htmlFor: "presetName" }, { children: "Name" })), _jsx("input", { type: "text", id: "presetName", className: "w-full p-2 border rounded", value: virtualPatientState.name ? virtualPatientState.name : '', onChange: function (e) { return setName(e.target.value); } })] })), _jsx("div", __assign({ className: "mb-4" }, { children: _jsx("label", __assign({ className: "block text-gray-700 mb-2", htmlFor: "description" }, { children: "Description" })) }))] }));
    }
    function savingContent() {
        var sexColor = virtualPatientState.staticData.patientProfile.patientType === PatientType.female1yo ? "text-mirno-purple" : "text-white";
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'flex justify-between mb-4 items-center' }, { children: [_jsx("h2", __assign({ className: "text-xl font-bold" }, { children: "Saving Virtual Patient..." })), _jsx(ClipLoader, {})] })), _jsx("div", __assign({ className: 'rounded-md shadow-md p-2 bg-mirno-dark text-mirno-light' }, { children: _jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsx("div", __assign({ className: "h-full self-center pr-2 pl-2 ".concat(sexColor) }, { children: _jsx(FaUser, { className: 'text-2xl' }) })), _jsxs("div", __assign({ className: 'flex flex-col w-full' }, { children: [_jsxs("div", __assign({ className: 'w-full flex' }, { children: [_jsxs("div", __assign({ className: 'flex flex-col w-full' }, { children: [_jsx("div", __assign({ className: 'font-bold' }, { children: virtualPatientState.name || 'Untitled' })), _jsx("div", __assign({ className: 'text-sm text-gray-400' }, { children: virtualPatientState.staticData.patientProfile.patientType }))] })), _jsx("div", __assign({ className: 'flex flex-col text-sm w-full' }, { children: _jsx("div", { className: 'text-sm text-gray-400 overflow-hidden text-ellipsis italic', style: {
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: 'vertical',
                                                    } }) }))] })), _jsx("div", __assign({ className: 'flex text-sm justify-between text-mirno-accent-pink opacity-50' }, { children: _jsxs("div", { children: [AuthService.currentUser().displayName || 'Unknown', AuthService.currentUser().email ? ' - ' + AuthService.currentUser().email : ''] }) }))] }))] })) }))] }));
    }
    function finishedSavingContent(presetId) {
        var sexColor = virtualPatientState.staticData.patientProfile.patientType === PatientType.female1yo ? "text-mirno-purple" : "text-white";
        var shareUrl = window.location.origin + '/demo/' + presetId;
        function goToCinemedic() {
            window.open(shareUrl, '_blank');
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'flex justify-between mb-4 items-center' }, { children: [_jsx("h2", __assign({ className: "text-xl font-bold" }, { children: "Saved!" })), _jsx("button", __assign({ className: "", onClick: onClose }, { children: _jsx(FaX, {}) }))] })), _jsx("div", __assign({ className: 'rounded-md shadow-md p-2 bg-mirno-dark text-mirno-light' }, { children: _jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsx("div", __assign({ className: "h-full self-center pr-2 pl-2 ".concat(sexColor) }, { children: _jsx(FaUser, { className: 'text-2xl' }) })), _jsxs("div", __assign({ className: 'flex flex-col w-full' }, { children: [_jsxs("div", __assign({ className: 'w-full flex' }, { children: [_jsxs("div", __assign({ className: 'flex flex-col w-full' }, { children: [_jsx("div", __assign({ className: 'font-bold' }, { children: virtualPatientState.name || 'Untitled' })), _jsx("div", __assign({ className: 'text-sm text-gray-400' }, { children: virtualPatientState.staticData.patientProfile.patientType }))] })), _jsx("div", { className: 'flex flex-col text-sm w-full' })] })), _jsx("div", __assign({ className: 'flex text-sm justify-between text-mirno-accent-pink opacity-50' }, { children: _jsxs("div", { children: [AuthService.currentUser().displayName || 'Unknown', AuthService.currentUser().email ? ' - ' + AuthService.currentUser().email : ''] }) }))] }))] })) })), _jsxs("div", __assign({ className: 'flex mt-4 justify-between items-center' }, { children: [_jsx("button", __assign({ className: "mirnoMenuButton", onClick: goToCinemedic }, { children: "View in Cinemedic" })), _jsx(ShareComponent, { shareLink: shareUrl })] }))] }));
    }
    if (saving) {
        return savingContent();
    }
    else if (savedPresetId) {
        return finishedSavingContent(savedPresetId);
    }
    return (_jsxs(_Fragment, { children: [inputSection(), _jsxs("div", __assign({ className: "flex justify-end gap-2" }, { children: [_jsx("div", __assign({ className: 'mr-auto' }, { children: _jsx(UserProfileComponent, {}) })), _jsx("button", __assign({ className: "mirnoMenuButton", onClick: onClose }, { children: "Cancel" })), _jsx("button", __assign({ className: "mirnoMenuButton", onClick: onSave }, { children: "Save" }))] }))] }));
};
var SaveModal = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    var authedUser = useAppContext().authedUser;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var _b = useState(false), saving = _b[0], setSaving = _b[1];
    var _c = useState(undefined), savedPresetId = _c[0], setSavedPresetId = _c[1];
    function handleSave() {
        return __awaiter(this, void 0, void 0, function () {
            var presetId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSaving(true);
                        return [4 /*yield*/, VirtualPatientStateStorage.savePatientPreset(virtualPatientState)];
                    case 1:
                        presetId = _a.sent();
                        setSaving(false);
                        setSavedPresetId(presetId);
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsx(Transition, __assign({ show: open, enter: "transition-opacity duration-500", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition-opacity duration-500", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx("div", __assign({ className: "fixed inset-0 z-[9999999] bg-black bg-opacity-80 flex justify-center items-center" }, { children: _jsx("div", __assign({ className: "bg-white p-6 rounded-lg shadow-lg w-1/2" }, { children: authedUser != null ? (_jsx(SaveModalContent, { onClose: onClose, onSave: handleSave, saving: saving, savedPresetId: savedPresetId })) : (_jsxs(_Fragment, { children: [_jsx("h2", __assign({ className: "text-xl font-bold mb-4" }, { children: "Please Login to Save" })), _jsx(UserProfileComponent, {}), _jsx("button", __assign({ className: "bg-blue-500 text-white px-4 py-2 rounded", onClick: onClose }, { children: "Close" }))] })) })) })) })));
};
export default SaveModal;
