var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { StateProgressionVerticalTimeline } from "./VerticalTimeline/VerticalTimeline";
import VirtualPatientStateStorage from "../../../components/Database/VirtualPatientStateStorage";
import { useCinemedicSupervisorDataContext } from "../Data/CinemedicSupervisorDataContext";
var buttonStyles = "w-full px-4 py-2 rounded-lg active:shadow-inner border flex items-center justify-center gap-2 space-x-2 text-sm bg-gray-50";
var StateSelectModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    var cinemedicSessionData = useCinemedicSupervisorDataContext().cinemedicSessionData;
    var _b = React.useState(undefined), initialState = _b[0], setInitialState = _b[1];
    var _c = React.useState([]), availableStates = _c[0], setAvailableStates = _c[1];
    var loadedForDocIdRef = React.useRef(undefined);
    React.useEffect(function () {
        if (!cinemedicSessionData || !cinemedicSessionData.docId) {
            return;
        }
        if (loadedForDocIdRef.current === cinemedicSessionData.docId) {
            return;
        }
        loadedForDocIdRef.current = cinemedicSessionData.docId;
        VirtualPatientStateStorage.getPreset(cinemedicSessionData.docId).then(function (state) {
            console.log("Got Preset", state);
            setInitialState(state === null || state === void 0 ? void 0 : state.virtualPatientState.medicalState);
        });
        VirtualPatientStateStorage.listAllProgressionStates(cinemedicSessionData.docId).then(function (states) {
            setAvailableStates(states);
        });
    }, [cinemedicSessionData]);
    return (_jsx(AnimatePresence, { children: isOpen && (_jsx(Dialog, __assign({ open: isOpen, onClose: onClose, className: "relative z-50" }, { children: _jsx("div", __assign({ className: "fixed inset-0 flex items-center justify-center bg-gray-50 h-full overflow-hidden" }, { children: _jsx(motion.div, __assign({ className: "w-full h-full max-w-lg p-6 bg-white rounded-lg shadow-lg overflow-y-auto", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: _jsxs("div", __assign({ className: "flex flex-col items-center justify-between w-full" }, { children: [initialState &&
                                _jsx(StateProgressionVerticalTimeline, { initialState: initialState, states: availableStates, onClose: onClose }), _jsx("button", __assign({ onClick: onClose, className: "".concat(buttonStyles, " !bg-blue-600 text-white mt-4") }, { children: "Close" }))] })) })) })) }))) }));
};
export default StateSelectModal;
