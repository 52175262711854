var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from "framer-motion";
import React from "react";
import { IoIosArrowUp } from "react-icons/io";
export var TimelineItem = function (_a) {
    var label = _a.label, state = _a.state, children = _a.children;
    var _b = React.useState(state === "current"), isExpanded = _b[0], setIsExpanded = _b[1];
    var getCardClasses = function () {
        switch (state) {
            case "past":
                return "bg-gray-50 text-gray-400";
            case "current":
                return "bg-blue-50 border-blue-500 text-blue-700";
            case "future":
                return "bg-gray-100 text-gray-500";
            default:
                return "";
        }
    };
    var getDotClasses = function () {
        switch (state) {
            case "past":
                return "bg-gray-300";
            case "current":
                return "bg-blue-500";
            case "future":
                return "bg-gray-500";
            default:
                return "";
        }
    };
    return (_jsxs("div", __assign({ className: "relative flex items-center gap-4 w-full" }, { children: [_jsxs("div", __assign({ className: "z-10 flex flex-col items-center" }, { children: [_jsx("div", { className: "w-4 h-4 rounded-full ".concat(getDotClasses(), " border-2 border-gray-300") }), _jsx("div", { className: "w-1 flex-1 ".concat(state === "future" ? "bg-gray-300" : "bg-gray-400") })] })), _jsxs("div", __assign({ className: "w-full flex flex-col shadow-md rounded-lg border ".concat(getCardClasses()) }, { children: [_jsxs("div", __assign({ className: "p-4 flex items-center justify-between cursor-pointer", onClick: function () { return setIsExpanded(!isExpanded); } }, { children: [_jsx("span", __assign({ className: "font-semibold text-lg" }, { children: label })), _jsx(motion.div, __assign({ animate: { rotate: isExpanded ? 0 : 180 }, transition: { duration: 0.2 } }, { children: _jsx(IoIosArrowUp, { className: "text-gray-800 w-6 h-6" }) }))] })), _jsx(motion.div, __assign({ initial: { height: 0, overflow: "hidden" }, animate: { height: isExpanded ? "auto" : 0 }, transition: { duration: 0.3 }, className: "w-full" }, { children: _jsx("div", __assign({ className: "border-t border-gray-200 mt-2 p-4 w-full" }, { children: children })) }))] }))] })));
};
