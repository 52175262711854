import { useEffect, useRef } from "react";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
import { MonitorSection, useMonitorSettingsStore } from "./useMonitorSettingsStore";
export var useMonitorAlarms = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var _a = useMonitorSettingsStore(), monitorSettings = _a.monitorSettings, alarmsMutedFor = _a.alarmsMutedFor, connectionAlertsPrimedFor = _a.connectionAlertsPrimedFor;
    var _b = useMonitorSettingsStore.getState(), setMutedAlarms = _b.setMutedAlarms, setConnectionAlertsPrimedFor = _b.setConnectionAlertsPrimedFor;
    var connectionAlertsPrimedForRef = useRef(connectionAlertsPrimedFor);
    useEffect(function () {
        connectionAlertsPrimedForRef.current = connectionAlertsPrimedFor;
    }, [connectionAlertsPrimedFor]);
    var isHeartRateNormal = virtualPatientState.medicalState.vitals.heartRate >= monitorSettings.hr.thresholds.min && virtualPatientState.medicalState.vitals.heartRate <= monitorSettings.hr.thresholds.max;
    var isSPo2Normal = virtualPatientState.medicalState.vitals.spo2 >= monitorSettings.spo2.thresholds.min && virtualPatientState.medicalState.vitals.spo2 <= monitorSettings.spo2.thresholds.max;
    var isRRNormal = virtualPatientState.medicalState.vitals.respiratoryRate >= monitorSettings.rr.thresholds.min && virtualPatientState.medicalState.vitals.respiratoryRate <= monitorSettings.rr.thresholds.max;
    var ecgConnected = virtualPatientState.deviceStatus.ecg;
    var pulseOxConnected = virtualPatientState.deviceStatus.pulseOximeterLeftFoot;
    var isHeartRateOutsideThreshold = ecgConnected && !isHeartRateNormal;
    var isSPo2OutsideThreshold = pulseOxConnected && !isSPo2Normal;
    var isRROutsideThreshold = ecgConnected && !isRRNormal;
    var ecgConnectionAlert = connectionAlertsPrimedFor.ecg && !ecgConnected;
    var spo2ConnectionAlert = connectionAlertsPrimedFor.spo2 && !pulseOxConnected;
    useEffect(function () {
        var newAlarmsMutedFor = structuredClone(alarmsMutedFor);
        if (isHeartRateNormal) {
            newAlarmsMutedFor = newAlarmsMutedFor.filter(function (section) { return section !== MonitorSection.HR; });
        }
        if (isSPo2Normal) {
            newAlarmsMutedFor = newAlarmsMutedFor.filter(function (section) { return section !== MonitorSection.SPO2; });
        }
        if (isRRNormal) {
            newAlarmsMutedFor = newAlarmsMutedFor.filter(function (section) { return section !== MonitorSection.RR; });
        }
        if (newAlarmsMutedFor.length !== alarmsMutedFor.length) {
            setMutedAlarms(newAlarmsMutedFor);
        }
    }, [isHeartRateNormal, isSPo2Normal, isRRNormal, alarmsMutedFor, setMutedAlarms]);
    useEffect(function () {
        var newPrimedConnectionAlerts = structuredClone(connectionAlertsPrimedForRef.current);
        if (virtualPatientState.deviceStatus.ecg) {
            newPrimedConnectionAlerts.ecg = true;
        }
        if (virtualPatientState.deviceStatus.pulseOximeterLeftFoot) {
            newPrimedConnectionAlerts.spo2 = true;
        }
        setConnectionAlertsPrimedFor(newPrimedConnectionAlerts);
    }, [virtualPatientState, setConnectionAlertsPrimedFor]);
    function muteAllActiveAlarms() {
        var newMutedAlarms = [];
        if (!isHeartRateNormal) {
            newMutedAlarms.push(MonitorSection.HR);
        }
        if (!isSPo2Normal) {
            newMutedAlarms.push(MonitorSection.SPO2);
        }
        if (!isRRNormal) {
            newMutedAlarms.push(MonitorSection.RR);
        }
        setMutedAlarms(newMutedAlarms);
        setConnectionAlertsPrimedFor({ ecg: false, spo2: false });
    }
    function unmuteAllAlarms() {
        setMutedAlarms([]);
    }
    return {
        isHeartRateOutsideThreshold: isHeartRateOutsideThreshold,
        isSPo2OutsideThreshold: isSPo2OutsideThreshold,
        isRROutsideThreshold: isRROutsideThreshold,
        ecgConnectionAlert: ecgConnectionAlert,
        spo2ConnectionAlert: spo2ConnectionAlert,
        muteAllActiveAlarms: muteAllActiveAlarms,
        unmuteAllAlarms: unmuteAllAlarms
    };
};
