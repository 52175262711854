var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SkinSchema, ExposureSchema } from "mirno-shared/src/components/Skin";
import React from "react";
import { GoArrowUpRight, GoArrowDownRight } from "react-icons/go";
var sectionClasses = "flex flex-col items-start gap-1 text-gray-600";
var titleClasses = "text-xs opacity-50";
var contentClasses = "flex text-gray-500";
var getArrow = function (current, next) {
    var arrowClasses = "w-4 h-4 inline opacity-50";
    if (current === next)
        return null;
    return current < next ?
        _jsx(GoArrowUpRight, { className: arrowClasses })
        :
            _jsx(GoArrowDownRight, { className: arrowClasses });
};
var PercentageDelta = function (_a) {
    var label = _a.label, current = _a.current, next = _a.next;
    var currentStr = Math.round(current * 100);
    var nextStr = Math.round(next * 100);
    if (currentStr === nextStr)
        return null;
    return (_jsxs("div", __assign({ className: sectionClasses }, { children: [_jsx("div", __assign({ className: titleClasses }, { children: label })), _jsxs("div", __assign({ className: contentClasses }, { children: [getArrow(current, next), _jsxs("div", { children: [nextStr, " %"] })] }))] })));
};
function SkinDeltaDisplay(currentState, futureState) {
    var keys = SkinSchema.keyof().options;
    if (!futureState)
        return [];
    return keys.map(function (key) {
        var current = currentState[key];
        var future = futureState[key];
        if (typeof (current) !== "number" || typeof (future) !== "number")
            return null;
        return _jsx(PercentageDelta, { label: key, current: current, next: future }, key);
    });
}
;
function ExposureDeltaDisplay(currentState, futureState) {
    var keys = ExposureSchema.keyof().options;
    if (!futureState)
        return [];
    return keys.map(function (key) {
        var current = currentState[key];
        var future = futureState[key];
        if (typeof (current) !== "number" || typeof (future) !== "number")
            return null;
        return _jsx(PercentageDelta, { label: key, current: current, next: future }, key);
    });
}
;
export var MedicalDeltaDisplay = function (_a) {
    var currentState = _a.currentState, futureState = _a.futureState;
    var allElements = __spreadArray(__spreadArray([], SkinDeltaDisplay(currentState.skin, futureState.skin), true), ExposureDeltaDisplay(currentState.exposure, futureState.exposure), true);
    return (_jsx("div", __assign({ className: "grid grid-cols-3 text-md leading-tight gap-2" }, { children: allElements })));
};
