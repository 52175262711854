var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useCinemedicSupervisorDataContext } from '../../pages/Supervisor/Data/CinemedicSupervisorDataContext';
import { SwedenColors as ColorConfig } from './Data/Colors';
import useTime, { formatTime } from './useTime';
import { bpstr } from './MonitorValueFormatters';
import BpButton from "./bp-button.svg";
import { motion } from 'framer-motion';
export var BloodPressureRow = function (props) {
    var cinemedicSessionData = useCinemedicSupervisorDataContext().cinemedicSessionData;
    var currentTime = useTime().epochTime;
    var _a = React.useState(undefined), latestResult = _a[0], setLatestResult = _a[1];
    var _b = React.useState([]), history = _b[0], setHistory = _b[1];
    React.useEffect(function () {
        if (cinemedicSessionData && cinemedicSessionData.bloodPressureHistory) {
            var results = cinemedicSessionData.bloodPressureHistory.results;
            setLatestResult(results[0]);
            setHistory(results.slice(1, -1));
        }
    }, [cinemedicSessionData]);
    var historyToRender = props.doingMeasurement ? (latestResult ? __spreadArray([latestResult], history, true) : []) : history;
    return (_jsxs("div", __assign({ className: "text-[2vh] flex font-extrabold p-4 gap-8 w-full select-none", style: { color: ColorConfig.bp } }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("div", __assign({ className: 'text-[1.5em]' }, { children: "NIBP" })), _jsxs("div", __assign({ className: "flex flex-col text-[1em]" }, { children: [_jsx("span", { children: "Sys." }), _jsx("span", __assign({ className: 'pl-2', style: { opacity: 0.7 } }, { children: "170" })), _jsx("span", __assign({ className: 'pl-2' }, { children: "100" }))] }))] })), _jsxs("div", __assign({ className: "flex flex-col w-48 text-center pt-1" }, { children: [_jsxs("div", __assign({ className: 'flex justify-between text-[1em]' }, { children: [props.doingMeasurement ? _jsxs("span", { children: ["Cuff ", props.cuffPressure.toFixed(0)] }) : _jsx("span", { children: "Manual" }), _jsx("span", { children: formatTime(currentTime) })] })), props.doingMeasurement ?
                        _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'text-[2em]' }, { children: _jsx("span", { children: "--/--" }) })), _jsx("div", __assign({ className: 'text-[1.5em]' }, { children: "(--)" }))] })
                        :
                            _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'text-[2em]' }, { children: _jsx("span", { children: bpstr(latestResult) }) })), _jsxs("div", __assign({ className: 'text-[1.5em]' }, { children: ["(", latestResult ? latestResult.map : "--", ")"] }))] })] })), _jsx("div", { className: 'w-1 border-r border-red-400 h-full' }), _jsx("div", __assign({ className: "grid grid-rows-4 pt-1" }, { children: historyToRender.map(function (result, i) { return (_jsxs("div", __assign({ className: 'flex space-x-2' }, { children: [_jsx("span", { children: formatTime(result.time) }), _jsxs("span", { children: [result.systolic, "/", result.diastolic] }), _jsxs("span", { children: ["(", result.map, ")"] })] }), i)); }) })), _jsx("div", __assign({ className: 'ml-auto text-[1.5vh] h-full flex flex-col items-end justify-end' }, { children: _jsxs(motion.button, __assign({ className: "bg-blue-300 p-2 h-12 w-20 font-normal text-center flex flex-col items-center justify-center disabled:opacity-50 text-black disabled:pointer-events-none", onClick: props.runNIBPMeasurement, disabled: !props.canTakeMeasurement, whileTap: { scale: 0.9 } }, { children: [_jsx("img", { src: BpButton, alt: "bp-button", className: "h-[4vh] w-[4vh]" }), "Measure BP"] })) }))] })));
};
