var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useCinemedicSupervisorDataContext } from "../Data/CinemedicSupervisorDataContext";
import { motion } from "framer-motion";
import speakingAnimation from "./speakingAnimation.json";
import Lottie from "lottie-react";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
var SpeechButton = function (_a) {
    var speechData = _a.speechData, onClicked = _a.onClicked, currentPlayingId = _a.currentPlayingId;
    var isActive = currentPlayingId === speechData.id;
    return (_jsxs("button", __assign({ onClick: function () { return onClicked(speechData); }, className: "bg-gray-100 relative rounded px-4 py-2 border text-sm flex items-center justify-between shadow overflow-hidden\n                active:shadow-inner disabled:pointer-events-none disabled:opacity-50\n                ".concat(isActive ? "bg-blue-200" : "", "\n                "), disabled: currentPlayingId !== undefined }, { children: [_jsx("span", { children: speechData.displayPhrase }), isActive &&
                _jsx(Lottie, { animationData: speakingAnimation, loop: true, className: "w-4 h-4 scale-[2]" })] })));
};
var SpeechControlSection = function () {
    var _a, _b;
    var _c = React.useState(false), isExpanded = _c[0], setIsExpanded = _c[1];
    var playSpeech = useCinemedicSupervisorDataContext().playSpeech;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var _d = React.useState(undefined), playingId = _d[0], setPlayingId = _d[1];
    var playingIdRef = React.useRef(undefined);
    React.useEffect(function () {
        playingIdRef.current = playingId;
    }, [playingId]);
    var onPlaySpeech = React.useCallback(function (speechData) {
        if (playingIdRef.current !== undefined) {
            return;
        }
        setPlayingId(speechData.id);
        playSpeech(speechData.id);
        setTimeout(function () {
            setPlayingId(undefined);
        }, speechData.durationInSeconds * 1000);
    }, [playSpeech, setPlayingId]);
    var hasAnySpeechAvailable = virtualPatientState.availableSpeech && ((_a = virtualPatientState === null || virtualPatientState === void 0 ? void 0 : virtualPatientState.availableSpeech) === null || _a === void 0 ? void 0 : _a.length) > 0;
    if (!hasAnySpeechAvailable) {
        return null;
    }
    return (_jsxs("section", __assign({ className: "bg-white shadow rounded-lg p-4" }, { children: [_jsxs("button", __assign({ className: "flex items-center justify-between cursor-pointer w-full disabled:pointer-events-none disabled:opacity-50", onClick: function () { return setIsExpanded(!isExpanded); }, disabled: !hasAnySpeechAvailable }, { children: [_jsxs("h2", __assign({ className: "text-base font-semibold text-gray-800" }, { children: [_jsx("span", { children: "Speech" }), !hasAnySpeechAvailable &&
                                _jsx("span", __assign({ className: "text-red-500 text-xs pl-2" }, { children: "(not available)" }))] })), _jsx(motion.div, __assign({ animate: { rotate: isExpanded ? 180 : 0 }, transition: { duration: 0.2 } }, { children: _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor", className: "w-5 h-5" }, { children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M19 9l-7 7-7-7" }) })) }))] })), _jsx(motion.div, __assign({ initial: { height: 0, overflow: "hidden" }, animate: { height: isExpanded ? "auto" : 0 }, transition: { duration: 0.3 } }, { children: _jsx("div", __assign({ className: "grid grid-cols-2 gap-3 mt-2" }, { children: (_b = virtualPatientState.availableSpeech) === null || _b === void 0 ? void 0 : _b.map(function (audio, index) { return (_jsx(SpeechButton, { speechData: audio, onClicked: onPlaySpeech, currentPlayingId: playingId }, index)); }) })) }))] })));
};
export default SpeechControlSection;
