var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PatientType } from 'mirno-shared/src/PatientState';
var GPT_MODEL = "gpt-4o";
var VALID_PATIENT_TYPES = [PatientType.female1yo]; // Object.values(PatientType)
var VALID_PATIENT_TYPES_COMMENT = '';
if (VALID_PATIENT_TYPES.length === 1) {
    VALID_PATIENT_TYPES_COMMENT = "Must be \"".concat(VALID_PATIENT_TYPES[0], "\"");
}
else {
    var formattedTypes = VALID_PATIENT_TYPES.map(function (type) { return "\"".concat(type, "\""); }).join(", ");
    VALID_PATIENT_TYPES_COMMENT = "From ".concat(formattedTypes);
}
var PATIENT_INFO_PROMPT = "\nYou are to take the user input and convert it into a detailed description for an emergency medical simulation scenario. This simulation scenario is for a patient arriving to the emergency room.\n\nFor required values, you must make a decision on what the value should be while adhering to the user's input. For optional values, do not specify anything unless the user specifically requests it.\n\nIt is vital that you don't list possibilities or \"maybes\", it is your responsibility to make a decision on what the values should be.\n\nReply as json, using the following format:\n\n{\n    patientProfile: {\n        ageAndSex: string, // ".concat(VALID_PATIENT_TYPES_COMMENT, "\n        weight: string, // Metric\n        medicalHistory: string, // A description of the known medical history\n        sex: string, // \"male\" or \"female\"\n        age: string, // Age, eg \"18 months\", \"42 years\",\n    },\n    simulationInstructions: {   // Use this object to list information required to accurately simulate the medical scenario. Information here will be visible to the instructor, but hidden from the learner.\n        rootCauses: string, // Root cause(s) of the presenting complaint.\n        actionsSoFar: string | null,   // OPTIONAL: If any interventions or actions have been taken so far, list the details here (including details such as dose, route, volume, method, etc).\n        unusualResponsesToFutureInterventions: string | null // OPTIONAL: If the simulation should have any unusual responses to learner actions (eg allergies) list them here.\n    },\n    presentingComplaint: string // The presenting complaint of the patient, as described by the caregiver. This must not have any information that would not be available to the caregiver.\n}\n").trim();
export function ValidateRequest(human_input, openai) {
    return __awaiter(this, void 0, void 0, function () {
        var systemPrompt, completionResponse, responseContent, ok;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    systemPrompt = "\nYou are to validate a users request in the context of the capabilities of the system.\nIf there are any problems, reply with a formatted error message indicating the problems, one per line. Also suggest valid values. Brief, telegraphic style, no extra wording.\nIf there are no problems, simply reply with \"OK\".\n    \n# Realism\n\nThe system is only capable of simulating conditions, symptoms, interventions, etc which exist in the world today. No scifi, fantasy, ancient, future, etc.\n\n# System Capabilities\n\n## Valid age/sex combinations\n".concat(VALID_PATIENT_TYPES.join(", "), "\n\n## Syndromes\nWe do not support any syndromes\n\n## Races\nWe don't support any other races than white at the moment.\n\n## Symptoms\nWe do not support simulations of trauma or injury.\n    ").trim();
                    return [4 /*yield*/, openai.chat.completions.create({
                            model: GPT_MODEL,
                            temperature: 0,
                            max_tokens: 4095,
                            messages: [
                                {
                                    role: "system",
                                    content: systemPrompt
                                },
                                {
                                    role: "user",
                                    content: human_input
                                }
                            ]
                        })];
                case 1:
                    completionResponse = _a.sent();
                    responseContent = completionResponse.choices[0].message.content;
                    ok = responseContent.toLowerCase() === "ok";
                    return [2 /*return*/, { valid: ok, validationError: responseContent }];
            }
        });
    });
}
export function GeneratePatientStaticData(human_input, openai) {
    return __awaiter(this, void 0, void 0, function () {
        var completionResponse, responseContent, data, staticData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openai.chat.completions.create({
                        model: GPT_MODEL,
                        temperature: 0,
                        max_tokens: 4095,
                        response_format: { type: "json_object" },
                        messages: [
                            {
                                role: "system",
                                content: PATIENT_INFO_PROMPT
                            },
                            {
                                role: "user",
                                content: human_input
                            }
                        ]
                    })];
                case 1:
                    completionResponse = _a.sent();
                    responseContent = completionResponse.choices[0].message.content;
                    data = JSON.parse(responseContent);
                    staticData = {
                        patientProfile: {
                            medicalHistory: data.patientProfile.medicalHistory,
                            weight: parseFloat(data.patientProfile.weight),
                            patientType: data.patientProfile.ageAndSex,
                            age: data.patientProfile.age,
                            sex: data.patientProfile.sex
                        },
                        presentingComplaint: data.presentingComplaint,
                        simulationInstructions: {
                            rootCauses: data.simulationInstructions.rootCauses,
                            unusualResponsesToFutureInterventions: data.simulationInstructions.unusualResponsesToFutureInterventions || undefined,
                        }
                    };
                    return [2 /*return*/, staticData];
            }
        });
    });
}
