var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Form from '@rjsf/core'; // Required to use a theme
import { zodToJsonSchema } from 'zod-to-json-schema';
import validator from '@rjsf/validator-ajv8';
import { FirestoreDoc_VirtualPatientStateSchema } from 'mirno-shared/src/types/DatabaseTypes';
var jsonSchema = zodToJsonSchema(FirestoreDoc_VirtualPatientStateSchema, {
    $refStrategy: "none",
    name: "VirtualPatientState",
});
var ObjectFieldTemplate = function (_a) {
    var title = _a.title, description = _a.description, properties = _a.properties;
    return (_jsxs("div", __assign({ className: "card mb-4" }, { children: [title && _jsx("div", __assign({ className: "card-header" }, { children: _jsx("h5", { children: title }) })), _jsxs("div", __assign({ className: "card-body" }, { children: [description && _jsx("p", __assign({ className: "card-text" }, { children: description })), properties.map(function (prop) { return (_jsx("div", __assign({ className: "mb-3" }, { children: prop.content }), prop.name)); })] }))] })));
};
var DevVirtualPatientEditor = function () {
    React.useEffect(function () {
        // Create a <link> element for Bootstrap
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css';
        link.crossOrigin = 'anonymous';
        // Append it to the document head
        document.head.appendChild(link);
        // Cleanup function to remove the stylesheet when the component is unmounted
        return function () {
            document.head.removeChild(link);
        };
    }, []);
    return (_jsx("div", __assign({ className: 'w-full h-full p-8 overflow-y-scroll\n        ' }, { children: _jsx(Form, { schema: jsonSchema, validator: validator, templates: { ObjectFieldTemplate: ObjectFieldTemplate } }) })));
};
export default DevVirtualPatientEditor;
