var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import "./Monitor.css";
import { GoMute, GoUnmute } from "react-icons/go";
import { AnimWave } from './Curves/AnimatedWaveRender';
export var MonitorRow = function (_a) {
    var id = _a.id, topText = _a.topText, centerText = _a.centerText, bottomText = _a.bottomText, color = _a.color, bpm = _a.bpm, waveData = _a.waveData, numBeatsInCurve = _a.numBeatsInCurve, displaySeconds = _a.displaySeconds, showCurve = _a.showCurve, muted = _a.muted, onSetMuted = _a.onSetMuted, alarmActive = _a.alarmActive, _b = _a.randomness, randomness = _b === void 0 ? 0 : _b;
    var muteButton = function () {
        if (!onSetMuted)
            return null;
        return (_jsx("div", __assign({ className: "text-white/50 rounded-full p-1 ml-4" }, { children: muted ?
                _jsx(GoMute, { onClick: function () { return onSetMuted(false); } })
                :
                    _jsx(GoUnmute, { onClick: function () { return onSetMuted(true); }, style: { color: color } }) })));
    };
    var valueAnimStyle = alarmActive ? "blink 1s infinite" : "";
    return (_jsxs("div", __assign({ className: "flex gap-4 p-2 h-full" }, { children: [_jsx("style", { children: "\n          @keyframes blink {\n            0%, 100% {\n              opacity: 1;\n            }\n            50% {\n              opacity: 0;\n            }\n          }\n        " }), _jsx("div", __assign({ className: "w-1/4 px-2 md:px-4 min-w-32 w-auto overflow-hidden", style: { flex: "0.1", width: "auto", color: color } }, { children: _jsxs("div", __assign({ className: "flex flex-col place-content-center space-y-2 relative h-full\n                    text-[2vh]\n                ", id: "hr" }, { children: [_jsxs("div", __assign({ className: "flex justify-between w-full" }, { children: [_jsx("span", { children: topText }), muteButton()] })), _jsx("div", __assign({ className: "text-[2em]" }, { children: _jsx("span", __assign({ id: "center_text", style: { animation: valueAnimStyle } }, { children: centerText })) })), _jsx("div", __assign({ className: "" }, { children: bottomText }))] })) })), _jsx("div", __assign({ className: "flex-1 flex justify-end contents h-full w-full" }, { children: _jsx("div", __assign({ className: "w-full h-full relative ".concat(showCurve ? "" : "opacity-0") }, { children: waveData &&
                        _jsx(AnimWave, { id: id, waveData: waveData, bpm: bpm, color: color, numBeatsInCurve: numBeatsInCurve, displaySeconds: displaySeconds, randomness: randomness }) })) }))] })));
};
