// config/alarmConfig.ts
// Example alarm patterns
export var ALARM_CONFIG = {
    normalBeep: {
        frequency: 1000,
        beepDurationMs: 125,
        pauseDurationMs: 875,
        repeatCount: 1,
        type: 'sine',
        volume: 0.3,
    },
    warning: {
        frequency: 660,
        beepDurationMs: 300,
        pauseDurationMs: 200,
        repeatCount: 3,
        type: 'sine',
        volume: 0.4,
    },
    critical: {
        frequency: 440,
        beepDurationMs: 250,
        pauseDurationMs: 250,
        repeatCount: 5,
        type: 'square',
        volume: 0.5,
    },
};
