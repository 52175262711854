import { create } from "zustand";
import { InterpolateMedicalState } from "mirno-shared/src/interpolation/Interpolator";
var setInterpolationAlpha = function (state, alpha) {
    if (state.stateA === null || state.stateB === null) {
        return state; // No change if stateA or stateB are null
    }
    var interpolatedState = InterpolateMedicalState(state.stateA, state.stateB, alpha);
    return {
        interpolationAlpha: alpha,
        interpolatedState: interpolatedState,
    };
};
// Create the Zustand store
var useDynamicVirtualPatient = create(function (set) { return ({
    stateA: null,
    stateB: null,
    interpolationAlpha: 0,
    interpolatedState: null,
    activeActions: [],
    isPlaying: false,
    setIsPlaying: function (playing) {
        return set({
            isPlaying: playing,
        });
    },
    setActiveActions: function (actions) {
        return set({
            activeActions: actions,
        });
    },
    initializeState: function (a, b) {
        return set({
            stateA: a,
            stateB: b,
            interpolationAlpha: 0,
            interpolatedState: a,
        });
    },
    // Function to update the fixed states and recalculate the interpolatedValue
    setStates: function (a, b) {
        return set(function (state) {
            var newState = {
                stateA: a,
                stateB: b,
                interpolationAlpha: 0,
                interpolatedState: InterpolateMedicalState(a, b, 0),
            };
            return newState;
        });
    },
    interpolationDurationSeconds: 869,
    setInterpolationDurationSeconds: function (seconds) {
        return set({
            interpolationDurationSeconds: seconds,
        });
    },
    setInterpolationTimeSeconds: function (seconds) {
        return set(function (state) {
            return setInterpolationAlpha(state, seconds / state.interpolationDurationSeconds);
        });
    },
}); });
export default useDynamicVirtualPatient;
