import { z } from "zod";
export var SkinSchema = z.object({
    faceRedness: z.number().describe("intensity, 0 - 1"),
    sweaty: z.number().describe("intensity, 0 - 1"),
    cyanosisBody: z.number().describe("intensity, 0 - 1"),
    cyanosisFace: z.number().describe("intensity, 0 - 1"),
    mottled: z.number().describe("intensity, 0 - 1"),
    paleness: z.number().describe("intensity, 0 - 1"),
    liverEnlargement: z.number().describe("intensity, 0 - 1"),
    itching: z.number().describe("intensity, 0 - 1"),
});
export var ExposureSchema = z.object({
    burns: z.number().describe("intensity, 0 - 1"),
    rashes: z.number().describe("intensity, 0 - 1"),
    wounds: z.number().describe("intensity, 0 - 1"),
    swelling: z.number().describe("intensity, 0 - 1"),
    purpura: z.number().describe("intensity, 0 - 1"),
    petechiae: z.number().describe("intensity, 0 - 1"),
    urticaria: z.number().describe("intensity, 0 - 1"),
    angioedema: z.number().describe("intensity, 0 - 1"),
    description: z.string().optional(),
});
export var DefaultSkinState = {
    faceRedness: 0,
    sweaty: 0,
    cyanosisBody: 0,
    cyanosisFace: 0,
    mottled: 0,
    paleness: 0,
    liverEnlargement: 0,
    itching: 0,
};
export var DefaultExposureState = {
    burns: 0,
    rashes: 0,
    wounds: 0,
    swelling: 0,
    purpura: 0,
    petechiae: 0,
    urticaria: 0,
    angioedema: 0,
};
