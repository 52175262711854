import { z } from "zod";
import { BloodGasSchema } from "./components/BloodGas";
import { BreathingSoundSchema, WorkOfBreathingSchema } from "./components/Breathing";
import { CirculationSchema } from "./components/Circulation";
import { DeviceStatusSchema } from "./components/Devices";
import { DisabilitySchema } from "./components/Disability";
import { SkinSchema, ExposureSchema } from "./components/Skin";
import { VitalsSchema } from "./components/Vitals";
export var PatientType;
(function (PatientType) {
    PatientType["male1yo"] = "1 year old boy";
    PatientType["male5yo"] = "5 year old boy";
    PatientType["male10yo"] = "10 year old boy";
    PatientType["female1yo"] = "1 year old girl";
    PatientType["female5yo"] = "5 year old girl";
    PatientType["female10yo"] = "10 year old girl";
    PatientType["female44yo"] = "44 year old woman";
})(PatientType || (PatientType = {}));
export var Sex;
(function (Sex) {
    Sex["male"] = "male";
    Sex["female"] = "female";
})(Sex || (Sex = {}));
export var PatientTypeSchema = z.enum(Object.values(PatientType));
export var SexSchema = z.enum(Object.values(Sex));
export var PatientSetupSchema = z.object({
    patientType: PatientTypeSchema,
});
export var PresetDataSchema = z.object({
    name: z.string().optional(),
    description: z.string().optional(),
});
export var ExaminationStateSchema = z.object({
    eyesOpen: z.boolean(),
    mouthOpen: z.boolean(),
    clothed: z.boolean(),
});
export var InjuriesStateSchema = z.object({
    leftArm: z.boolean().optional(),
    rightArm: z.boolean().optional(),
});
export var VirtualPatientMedicalStateSchema = z.object({
    vitals: VitalsSchema,
    breathingSounds: BreathingSoundSchema,
    workOfBreathing: WorkOfBreathingSchema,
    skin: SkinSchema,
    circulation: CirculationSchema,
    disability: DisabilitySchema,
    exposure: ExposureSchema,
    bloodGas: BloodGasSchema.optional(),
    injuries: InjuriesStateSchema.optional(),
});
export var VirtualPatientStaticDataSchema = z.object({
    patientProfile: z.object({
        patientType: PatientTypeSchema,
        age: z.string(),
        sex: SexSchema,
        weight: z.number(),
        medicalHistory: z.string(),
    }),
    simulationInstructions: z.object({
        rootCauses: z.string(),
        unusualResponsesToFutureInterventions: z.string().optional(),
    }),
    presentingComplaint: z.string(),
});
export var VirtualPatientProgressionDataSchema = z.object({
    summaryOfChange: z.string().nullable().optional(),
    elapsedTimeMinutes: z.number(),
    actionsSoFar: z.array(z.string()).nullable().optional(),
});
export var CinemedicSpeechDataSchema = z.object({
    id: z.string(),
    displayPhrase: z.string(),
    durationInSeconds: z.number(),
});
export var AvailableSpeechSchema = z.array(CinemedicSpeechDataSchema);
export var VirtualPatientStateSchema = z.object({
    name: z.string().optional(),
    staticData: VirtualPatientStaticDataSchema,
    progressionData: VirtualPatientProgressionDataSchema.optional(),
    medicalState: VirtualPatientMedicalStateSchema,
    examinationState: ExaminationStateSchema,
    deviceStatus: DeviceStatusSchema,
    availableSpeech: AvailableSpeechSchema.optional(),
});
export var DefaultProgressionData = {
    summaryOfChange: null,
    elapsedTimeMinutes: 0,
    actionsSoFar: null,
};
export var DefaultStaticData = {
    patientProfile: {
        patientType: "44 year old woman",
        age: "44 years",
        sex: Sex.female,
        weight: 75,
        medicalHistory: "",
    },
    simulationInstructions: {
        rootCauses: "",
    },
    presentingComplaint: "",
};
