import { z } from "zod";
export var DeviceStatusSchema = z.object({
    ecg: z.boolean(),
    pulseOximeterLeftFoot: z.boolean(),
    pulseOximeterRightFoot: z.boolean(),
    nibpLeftArm: z.boolean(),
    nibpRightArm: z.boolean(),
    nasalCannula: z.boolean(),
    ivCatheterLeftHand: z.boolean(),
    ivCatheterRightHand: z.boolean(),
    ivCatheterLeftArm: z.boolean(),
    ivCatheterRightArm: z.boolean(),
    faceMask: z.boolean(),
});
export var DefaultDeviceStatus = {
    ecg: false,
    pulseOximeterLeftFoot: false,
    pulseOximeterRightFoot: false,
    nibpLeftArm: false,
    nibpRightArm: false,
    nasalCannula: false,
    ivCatheterLeftHand: false,
    ivCatheterRightHand: false,
    ivCatheterLeftArm: false,
    ivCatheterRightArm: false,
    faceMask: false,
};
