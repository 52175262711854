import { z } from "zod";
export var BloodPressureSchema = z.object({
    systolic: z.number().describe("mmHg"),
    diastolic: z.number().describe("mmHg"),
});
export function parseBloodPressure(bloodPressure) {
    var _a = bloodPressure.split("/"), systolic = _a[0], diastolic = _a[1];
    return { systolic: Number(systolic), diastolic: Number(diastolic) };
}
export function formatBloodPressure(bloodPressure) {
    return "".concat(Math.round(bloodPressure.systolic), "/").concat(Math.round(bloodPressure.diastolic));
}
