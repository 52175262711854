var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { motion } from "framer-motion";
import { useVirtualPatientStore } from "../../storage/VirtualPatientStore";
import { useCinemedicSupervisorDataContext } from "./Data/CinemedicSupervisorDataContext";
var chipStyles = "rounded-full bg-slate-100 px-2 py-1 w-max text-xs md:text-sm";
var InfoSection = function () {
    var _a, _b;
    var isExpanded = React.useState(false)[0];
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var _c = useCinemedicSupervisorDataContext(), setGameOverScreenActive = _c.setGameOverScreenActive, cinemedicSessionData = _c.cinemedicSessionData;
    // const gameOverScreenActiveRef = React.useRef<boolean>(false);
    // React.useEffect(() => {
    //     gameOverScreenActiveRef.current = cinemedicSessionData?.systemControlState?.gameOverScreenActive ?? false;
    // }, [cinemedicSessionData]);
    var gameOverScreenActive = (_b = (_a = cinemedicSessionData === null || cinemedicSessionData === void 0 ? void 0 : cinemedicSessionData.systemControlState) === null || _a === void 0 ? void 0 : _a.gameOverScreenActive) !== null && _b !== void 0 ? _b : false;
    return (_jsxs("section", __assign({ className: "bg-white shadow rounded-lg p-4" }, { children: [_jsxs("div", __assign({ className: "flex items-center justify-between cursor-pointer" }, { children: [_jsxs("h2", __assign({ className: "text-base font-semibold text-gray-800 flex space-x-2" }, { children: [_jsx("div", { children: virtualPatientState.name }), _jsxs("div", __assign({ className: "text-gray-500 flex gap-1 items-start flex-wrap" }, { children: [_jsx("span", __assign({ className: chipStyles }, { children: virtualPatientState.staticData.patientProfile.age })), _jsx("span", __assign({ className: chipStyles }, { children: virtualPatientState.staticData.patientProfile.sex })), _jsxs("span", __assign({ className: chipStyles }, { children: [virtualPatientState.staticData.patientProfile.weight, " kg"] }))] }))] })), _jsx("button", __assign({ className: "text-sm text-blue-500", onClick: function () { return setGameOverScreenActive(!gameOverScreenActive); } }, { children: gameOverScreenActive ? "Resume Session" : "End Session" }))] })), _jsx(motion.div, __assign({ initial: { height: 0, overflow: "hidden" }, animate: { height: isExpanded ? "auto" : 0 }, transition: { duration: 0.3 } }, { children: _jsx("div", { className: "mt-3 space-y-3" }) }))] })));
};
export default InfoSection;
