var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import EditableVitalsSection from "../StateRenderers/EditableVitalsSection";
import React from "react";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
import { useStateSelectHook } from "./stateSelectHook";
import { isEqual } from "lodash";
import { DefaultMedicalState } from "mirno-shared/src/DefaultState";
import useDynamicVirtualPatient from "../../DemoMode/DynamicVirtualPatientStore";
var buttonStyles = "w-full px-4 py-2 rounded-lg active:shadow-inner border flex items-center justify-center gap-2 space-x-2 text-sm bg-gray-50";
var CustomStateModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var setTargetState = useStateSelectHook().setTargetState;
    var setIsPlaying = useDynamicVirtualPatient().setIsPlaying;
    var _b = React.useState(virtualPatientState.medicalState), editingState = _b[0], setEditingState = _b[1];
    var virtualPatientStateRef = React.useRef(virtualPatientState);
    React.useEffect(function () {
        virtualPatientStateRef.current = virtualPatientState;
    }, [virtualPatientState]);
    var modified = React.useMemo(function () {
        return !isEqual(editingState, virtualPatientState.medicalState);
    }, [editingState, virtualPatientState.medicalState]);
    React.useEffect(function () {
        if (isOpen) {
            setEditingState(virtualPatientStateRef.current.medicalState);
            setIsPlaying(false);
        }
    }, [isOpen, setEditingState, setIsPlaying]);
    var onTransitionDefault = function (durationInMinutes) {
        var fullMedicalState = __assign(__assign({}, DefaultMedicalState), editingState);
        // const progressionState: VirtualPatientProgressionState = {
        //     shortName: "Custom State",
        //     description: "N/A",
        //     medicalState: fullMedicalState,
        //     defaultDurationMinutes: durationInMinutes,
        // };
        onClose();
        setTargetState(fullMedicalState, "Custom State", durationInMinutes * 60);
        setTimeout(function () {
            setIsPlaying(true);
        }, 1000);
    };
    return (_jsx(AnimatePresence, { children: isOpen && (_jsx(Dialog, __assign({ open: isOpen, onClose: onClose, className: "relative z-50" }, { children: _jsx("div", __assign({ className: "fixed inset-0 flex items-center justify-center" }, { children: _jsxs(motion.div, __assign({ className: "bg-white w-full h-full flex flex-col", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsx("div", __assign({ className: "flex items-center justify-around w-full shadow-[0px_0px_5px_0px_rgba(0,_0,_0,_0.3)] w-full p-4 z-10" }, { children: _jsxs("h1", __assign({ className: "text-base font-semibold text-gray-800 space-x-1" }, { children: [_jsx("span", { children: "Edit State" }), modified && _jsx("span", __assign({ className: "text-blue-500" }, { children: " (modified)" }))] })) })), _jsx("main", __assign({ className: " overflow-auto relative p-4 space-y-4" }, { children: _jsx(EditableVitalsSection, { editingState: editingState, setEditingState: setEditingState }) }), "content"), _jsxs("div", __assign({ className: "shadow-[0px_0px_5px_0px_rgba(0,_0,_0,_0.3)] p-4 z-10 mt-auto space-y-4" }, { children: [modified && (_jsxs("div", __assign({ className: "grid grid-cols-2 gap-2" }, { children: [_jsx("button", __assign({ onClick: function () { return onTransitionDefault(1); }, className: "".concat(buttonStyles) }, { children: "1 Minute" })), _jsx("button", __assign({ onClick: function () { return onTransitionDefault(3); }, className: "".concat(buttonStyles, " bg-slate-700 text-gray-50") }, { children: "3 Minutes" }))] }))), _jsx("button", __assign({ onClick: onClose, className: "".concat(buttonStyles, " bg-red-700 text-gray-50 w-auto") }, { children: "Cancel" }))] }))] }), "modal") })) }))) }));
};
export default CustomStateModal;
