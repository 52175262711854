var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import VirtualPatientStateStorage from './Database/VirtualPatientStateStorage';
import ClipLoader from 'react-spinners/ClipLoader';
import { AuthService } from './Database/Authentication';
import { useAppContext } from '../AppContext';
var PresetSelectorModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onSelectedPreset = _a.onSelectedPreset;
    useAppContext();
    var _b = useState([]), presets = _b[0], setPresets = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    useEffect(function () {
        if (isOpen) {
            setLoading(true);
            VirtualPatientStateStorage.listVirtualPatientStates().then(function (presets) {
                setPresets(presets);
                setLoading(false);
            });
        }
    }, [isOpen]);
    var onPresetClick = function (preset) {
        onSelectedPreset(preset);
    };
    var ownedByMe = function (preset) {
        var user = AuthService.currentUser();
        return user ? preset.metadata.ownerId === user.uid : false;
    };
    if (!isOpen)
        return null;
    return (_jsx("div", __assign({ className: "fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50", onClick: onClose }, { children: _jsxs("div", __assign({ className: "relative top-20 mx-auto p-2 border w-1/2 shadow-lg rounded-md bg-white", onClick: function (e) { return e.stopPropagation(); } }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("div", { children: _jsx("h3", __assign({ className: "text-lg font-semibold text-gray-900" }, { children: "Select a Preset" })) }), _jsx("button", __assign({ onClick: onClose, className: "rounded-full bg-transparent p-2 hover:bg-gray-200" }, { children: _jsx("span", __assign({ className: "text-gray-500 text-xl" }, { children: "\u00D7" })) }))] })), loading ? (_jsx("div", __assign({ className: "flex justify-center mt-5" }, { children: _jsx(ClipLoader, { color: "#4A90E2", size: 50 }) }))) : (_jsx("div", __assign({ className: "mt-4 m-2" }, { children: presets.length > 0 ? (_jsx("div", __assign({ className: "flex flex-col divide-y divide-gray-200 max-h-96 gap-1 overflow-auto" }, { children: presets.map(function (preset) {
                            var _a;
                            return (_jsxs("div", __assign({ className: "p-3 hover:bg-gray-100 cursor-pointer flex flex-col gap-1 rounded border", onClick: function () { return onPresetClick(preset); } }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center text-sm text-gray-900" }, { children: [_jsx("span", __assign({ className: ownedByMe(preset) ? "font-bold" : "font-semibold" }, { children: preset.id })), _jsx("span", __assign({ className: "text-gray-500" }, { children: ((_a = preset.caseDescription) === null || _a === void 0 ? void 0 : _a.description) || "No description" }))] })), _jsxs("div", __assign({ className: "flex items-center text-xs text-gray-500" }, { children: [_jsx("span", __assign({ className: ownedByMe(preset) ? "font-bold mr-2" : "mr-2" }, { children: preset.metadata.ownerName })), _jsx("span", __assign({ className: "flex-grow text-right" }, { children: preset.virtualPatientState.staticData.patientProfile.patientType }))] }))] }), preset.id));
                        }) }))) : (_jsx("p", __assign({ className: "text-center text-gray-500" }, { children: "No presets available." }))) }))), _jsx("button", __assign({ className: "mt-4 w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-150 ease-in-out", onClick: onClose }, { children: "Close" }))] })) })));
};
export default PresetSelectorModal;
