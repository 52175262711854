import { useCallback } from 'react';
import { useGlobalAudioContext } from './AudioContextProvider';
import { playRepeatedTone, playTone } from './audioUtils';
import { ALARM_CONFIG } from './alarmConfig';
export function useAudioManager() {
    var audioContext = useGlobalAudioContext().audioContext;
    // Play a single beep or tone
    var playSingleTone = useCallback(function (frequency, durationMs, type, volume) {
        if (type === void 0) { type = 'sine'; }
        if (volume === void 0) { volume = 0.2; }
        if (!audioContext) {
            console.warn('AudioContext not available. Did you click "Enable Sound"?');
            return;
        }
        playTone(audioContext, frequency, durationMs, type, volume);
    }, [audioContext]);
    // Play repeated beep pattern
    var playBeepPattern = useCallback(function (frequency, beepDurationMs, pauseDurationMs, repeatCount, type, volume) {
        if (type === void 0) { type = 'sine'; }
        if (volume === void 0) { volume = 0.2; }
        if (!audioContext) {
            console.warn('AudioContext not available. Did you click "Enable Sound"?');
            return;
        }
        playRepeatedTone(audioContext, frequency, beepDurationMs, pauseDurationMs, repeatCount, type, volume);
    }, [audioContext]);
    /**
     * Plays one of the predefined alarm patterns from ALARM_CONFIG (e.g., 'normalBeep', 'warning', 'critical').
     */
    var playAlarm = useCallback(function (alarmName) {
        if (!audioContext) {
            console.warn('AudioContext not available. Did you click "Enable Sound"?');
            return;
        }
        var config = ALARM_CONFIG[alarmName];
        if (!config) {
            console.warn("Alarm config for \"".concat(alarmName.toString(), "\" not found."));
            return;
        }
        playRepeatedTone(audioContext, config.frequency, config.beepDurationMs, config.pauseDurationMs, config.repeatCount, config.type, config.volume);
    }, [audioContext]);
    return {
        playSingleTone: playSingleTone,
        playBeepPattern: playBeepPattern,
        playAlarm: playAlarm,
    };
}
