var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppProvider } from "../../AppContext";
import { SessionProvider, useSession } from "../Sessions/SessionContext";
import React, { useCallback, useEffect, useState } from "react";
import { UE5MessageType } from "../../components/ue5";
import VirtualPatientStateStorage from "../../components/Database/VirtualPatientStateStorage";
import CrashCartMenu from "../Cinemedic/Toolbar/CrashCartMenu";
import { useVirtualPatientStore } from "../../storage/VirtualPatientStore";
var Controller = function () {
    var _a = useSession(), sessionId = _a.sessionId, fullSessionData = _a.rawSessionData, writeDataToSession = _a.writeRawDataToSession, sendMessage = _a.sendMessage;
    var setVirtualPatientState = useVirtualPatientStore().setVirtualPatientState;
    var _b = useState(null), demoPreset = _b[0], setDemoPreset = _b[1];
    var resetDemo = useCallback(function () {
        if (!demoPreset)
            return;
        var data = {
            docId: demoPreset.id,
            rootDocId: demoPreset.id,
            virtualPatientState: demoPreset.virtualPatientState
        };
        writeDataToSession(data);
    }, [demoPreset, writeDataToSession]);
    useEffect(function () {
        VirtualPatientStateStorage.getPreset("UqgY1SjPMmVVSseG2vsX").then(function (doc) {
            if (doc) {
                setDemoPreset(doc);
                if (doc.virtualPatientState) {
                    if (!fullSessionData || !fullSessionData.data) {
                        resetDemo();
                    }
                }
            }
        });
    });
    useEffect(function () {
        if (!fullSessionData || !fullSessionData.data || !fullSessionData.data.virtualPatientState)
            return;
        setVirtualPatientState(JSON.parse(JSON.stringify(fullSessionData.data.virtualPatientState)));
    }, [fullSessionData, setVirtualPatientState]);
    function onIVApplied(location, gauge, catheterType) {
        console.log("IV applied at ".concat(location, " with ").concat(gauge, " ").concat(catheterType));
        if (!fullSessionData)
            return;
        var data = fullSessionData.data;
        data.virtualPatientState.deviceStatus.ivCatheterLeftArm = true;
        writeDataToSession(data);
    }
    function onIVRemoved() {
        console.log("IV removed");
        if (!fullSessionData)
            return;
        var data = fullSessionData.data;
        data.virtualPatientState.deviceStatus.ivCatheterLeftArm = false;
        writeDataToSession(data);
    }
    function onMedicationPrepared(medication, dose, doseUnit, route, dilutionVolume, diluentType) {
        sendMessage("syringe", { active: true, contents: "".concat(medication, " ").concat(dose, " ").concat(doseUnit) });
    }
    function onMedicationCanceled() {
        sendMessage("syringe", { active: false });
    }
    function onMonitorOptionSelected(option) {
        if (!fullSessionData)
            return;
        var data = fullSessionData.data;
        if (option === "ECG") {
            data.virtualPatientState.deviceStatus.ecg = !data.virtualPatientState.deviceStatus.ecg;
        }
        else if (option === "Pulse Oximeter") {
            data.virtualPatientState.deviceStatus.pulseOximeterLeftFoot = !data.virtualPatientState.deviceStatus.pulseOximeterLeftFoot;
        }
        else if (option === "NIBP") {
            data.virtualPatientState.deviceStatus.nibpRightArm = !data.virtualPatientState.deviceStatus.nibpRightArm;
        }
        writeDataToSession(data);
    }
    return (_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden bg-slate-700 text-white p-2' }, { children: [_jsxs("div", __assign({ className: "opacity-50 mb-2 flex justify-between" }, { children: [_jsx("div", __assign({ className: "font-mono" }, { children: "Cinemedic Pro Controller v0.0.2" })), _jsxs("div", __assign({ className: "font-mono" }, { children: ["Connected to: ", sessionId] }))] })), _jsxs("div", __assign({ className: "overflow-auto h-full flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex gap-2 mb-2" }, { children: [_jsx("button", __assign({ className: "bg-green-500 text-white p-2", onClick: function () { return sendMessage(UE5MessageType.setCamera, { cameraId: 0 }); } }, { children: "Top Camera" })), _jsx("button", __assign({ className: "bg-green-500 text-white p-2", onClick: function () { return sendMessage(UE5MessageType.setCamera, { cameraId: 1 }); } }, { children: "Side Camera" })), _jsx("button", __assign({ className: "bg-green-500 text-white p-2", onClick: function () { return sendMessage(UE5MessageType.setCamera, { cameraId: 2 }); } }, { children: "Face Camera" }))] })), _jsx("div", __assign({ className: "mb-2 flex justify-center" }, { children: _jsx("div", __assign({ className: "w-max min-w-64 bg-mirno-dark p-2 rounded text-lg" }, { children: _jsx(CrashCartMenu, { onIVApplied: onIVApplied, onIVRemoved: onIVRemoved, onMedicationPrepared: onMedicationPrepared, onMedicationCanceled: onMedicationCanceled, onMonitorOptionSelected: onMonitorOptionSelected }) })) })), _jsx("div", __assign({ className: "flex gap-2 mt-auto" }, { children: _jsx("button", __assign({ className: "bg-green-500 text-white p-2", onClick: resetDemo }, { children: "Reset Patient" })) }))] }))] })));
};
var VPCastControllerBasic = function () {
    return (_jsx(AppProvider, { children: _jsx(SessionProvider, { children: _jsx(Controller, {}) }) }));
};
export default VPCastControllerBasic;
