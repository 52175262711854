export var DefaultHybridControlData = {
    leftArm: false,
    rightArm: false
};
export var DefaultBloodPressureResultHistory = {
    results: []
};
export var DefaultSystemControlState = {
    gameOverScreenActive: false
};
