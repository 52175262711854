export var IcelandColors = {
    hr: "#0dff45",
    spo2: "#56dbfc",
    etco2: "white",
    bp: "#fc5ded"
};
export var SwedenColors = {
    hr: "#00ff00",
    spo2: "#edf72a",
    etco2: "#ffffff",
    bp: "#ff4444"
};
