var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import TimelineControl from "./TimelineControl";
var ProgressionControls = function (_a) {
    var onOpenStateSelectModal = _a.onOpenStateSelectModal, onOpenCustomStateModal = _a.onOpenCustomStateModal;
    return (_jsx("div", __assign({ className: "\n                hadow-[0px_0px_5px_0px_rgba(0,_0,_0,_0.3)]\n                w-full z-10 overflow-hidden flex flex-col max-h-[100vh]\n                mt-auto h-auto space-y-2 fixed bottom-0\n            " }, { children: _jsx("div", __assign({ className: "flex flex-col items-start space-y-4 w-full" }, { children: _jsx(TimelineControl, { onOpenStateSelectModal: onOpenStateSelectModal, onOpenCustomStateModal: onOpenCustomStateModal }) })) })));
};
export default ProgressionControls;
