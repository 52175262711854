var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';
import { FaLongArrowAltRight } from "react-icons/fa";
import playerAnimation from "./playerAnimation.json";
import Lottie from "lottie-react";
import { useCinemedicSupervisorDataContext } from '../Data/CinemedicSupervisorDataContext';
import useDynamicVirtualPatient from '../../DemoMode/DynamicVirtualPatientStore';
var ProgressControl = function (_a) {
    var onOpenStateSelectModal = _a.onOpenStateSelectModal, onOpenCustomStateModal = _a.onOpenCustomStateModal, onTransitionComplete = _a.onTransitionComplete;
    var _b = useCinemedicSupervisorDataContext(), activeInterpolationData = _b.activeInterpolationData, currentStateName = _b.currentStateName;
    var _c = useDynamicVirtualPatient(), interpolationAlpha = _c.interpolationAlpha, interpolationDurationSeconds = _c.interpolationDurationSeconds, isPlaying = _c.isPlaying;
    var setIsPlaying = useDynamicVirtualPatient.getState().setIsPlaying;
    var _d = useState(0), elapsedTime = _d[0], setElapsedTime = _d[1];
    var stateAName = currentStateName || undefined;
    var stateBName = activeInterpolationData === null || activeInterpolationData === void 0 ? void 0 : activeInterpolationData.targetStateName;
    React.useEffect(function () {
        setElapsedTime(Math.round(interpolationAlpha * interpolationDurationSeconds));
    }, [setElapsedTime, interpolationAlpha, interpolationDurationSeconds]);
    var togglePlayPause = function () {
        setIsPlaying(!isPlaying);
    };
    var formatTime = function (time) {
        var minutes = Math.floor(time / 60);
        var seconds = Math.floor(time % 60);
        return "".concat(minutes, ":").concat(seconds.toString().padStart(2, '0'));
    };
    var remainingTime = interpolationDurationSeconds - elapsedTime;
    var lottieRef = React.useRef(null);
    useEffect(function () {
        var _a;
        (_a = lottieRef.current) === null || _a === void 0 ? void 0 : _a.setSpeed(isPlaying ? 0.4 : 0);
    }, [isPlaying]);
    return (_jsxs("div", __assign({ className: "flex flex-col items-center bg-gray-800 text-white rounded-lg rounded-b-none w-full shadow-lg overflow-hidden", onClick: function (e) { onOpenStateSelectModal(); e.stopPropagation(); e.preventDefault(); } }, { children: [_jsxs("div", __assign({ className: 'flex w-full space-x-2 p-2' }, { children: [_jsx("div", __assign({ className: '' }, { children: _jsx(Lottie, { animationData: playerAnimation, loop: true, className: "w-8 h-8", lottieRef: lottieRef, style: { filter: isPlaying ? 'none' : 'grayscale(100%)' } }) })), _jsxs("div", __assign({ className: "flex flex-col mx-4 flex-grow" }, { children: [_jsxs("div", __assign({ className: "text-sm font-medium flex space-x-2 items-center" }, { children: [_jsx("span", { children: stateAName }), activeInterpolationData && _jsxs(_Fragment, { children: [_jsx(FaLongArrowAltRight, {}), " ", _jsx("span", { children: stateBName })] })] })), _jsx("div", __assign({ className: "text-xs text-gray-400 flex space-x-2 items-center justify-between" }, { children: activeInterpolationData ?
                                    _jsxs("span", { children: ["Remaining: ", formatTime(remainingTime)] })
                                    :
                                        _jsx("span", { children: "Select next state" }) }))] })), _jsx("button", __assign({ onClick: function (e) { togglePlayPause(); e.stopPropagation(); e.preventDefault(); }, className: "flex items-center justify-center w-8 h-8 bg-gray-700 rounded-full hover:bg-gray-600 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none", "aria-label": isPlaying ? 'Pause' : 'Play', disabled: !activeInterpolationData }, { children: isPlaying ? _jsx(FaPause, { className: "w-4 h-4" }) : _jsx(FaPlay, { className: "w-4 h-4" }) }))] })), _jsx("div", __assign({ className: "relative w-full h-1 bg-gray-600 rounded-full " }, { children: _jsx("div", { className: "absolute top-0 left-0 h-full bg-blue-500 rounded-full", style: { width: "".concat(interpolationAlpha * 100, "%") } }) }))] })));
};
export default ProgressControl;
