import { toInteger } from 'lodash';
import { formatBloodPressure } from 'mirno-shared/src/components/BloodPressure';
export function MAP(nibp) {
    var sbp = nibp.systolic;
    var dbp = nibp.diastolic;
    var vs_map = toInteger((sbp - dbp) / 3 + dbp);
    return vs_map;
}
export function str(value) {
    if (!value)
        return "--";
    return Math.round(value).toString();
}
export function bpstr(value) {
    if (!value)
        return "--/--";
    return formatBloodPressure(value);
}
export function ECGCenterText(deviceState, vitals) {
    if (deviceState.ecg) {
        return str(vitals.heartRate);
    }
    else {
        return "--";
    }
}
export function spo2CenterText(deviceState, vitals) {
    if (deviceState.pulseOximeterLeftFoot || deviceState.pulseOximeterRightFoot) {
        return str(vitals.spo2);
    }
    else {
        return "--";
    }
}
export function nibpCenterText(deviceState, vitals) {
    if (deviceState.nibpLeftArm || deviceState.nibpRightArm) {
        return bpstr(vitals.nibp);
    }
    else {
        return "--/--";
    }
}
export function nibpBottomText(deviceState, vitals) {
    if (deviceState.nibpLeftArm || deviceState.nibpRightArm) {
        return "(MAP = " + MAP(vitals.nibp) + ")";
    }
    else {
        return "";
    }
}
export function etco2CenterText(deviceState, vitals) {
    if (deviceState.ecg) {
        return str(vitals.respiratoryRate);
    }
    else {
        return "--";
    }
}
export function etco2BottomText(deviceState, vitals) {
    if (deviceState.nasalCannula) {
        return "ETCO2 mmHg " + str(vitals.etco2);
    }
    else {
        return "ETCO2 mmHg ---";
    }
}
