var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useVirtualPatientStore } from "../../../../storage/VirtualPatientStore";
import { TimelineItem } from "./TimelineItem";
import { TimelineVitalsDelta } from "./VitalsDelta";
import { MedicalDeltaDisplay } from "./GenericDelta";
import { useStateSelectHook } from "../stateSelectHook";
import useDynamicVirtualPatient from "../../../DemoMode/DynamicVirtualPatientStore";
import { DraggableNumericInput } from "../../StateRenderers/EditableValueComponents";
var buttonStyles = "w-full py-2 rounded-lg active:shadow-inner border items-center justify-center text-sm bg-blue-600 text-white";
var VerticalTimelineLayout = function (_a) {
    var children = _a.children;
    return (_jsxs("div", __assign({ className: "relative flex flex-col items-start w-full gap-4" }, { children: [_jsx("div", { className: "absolute left-[6px] top-0 h-full w-1 bg-gray-300" }), React.Children.map(children, function (child, index) { return (_jsx("div", __assign({ className: "w-full" }, { children: child }), index)); })] })));
};
var ConfigurableTimelineItem = function (props) {
    var _a = React.useState(props.progressionState.defaultDurationMinutes), transitionTimeMinutes = _a[0], setTransitionTimeMinutes = _a[1];
    function startTransition() {
        if (!props.onStartTransition)
            return;
        props.onStartTransition(props.progressionState, transitionTimeMinutes);
    }
    function getDisplayTime() {
        if (transitionTimeMinutes === 0) {
            return "Set immediately";
        }
        return "Start";
    }
    return (_jsx(TimelineItem, __assign({ label: props.progressionState.shortName, state: "future" }, { children: _jsxs("div", __assign({ className: "w-full flex h-full gap-2 flex-col" }, { children: [_jsx(TimelineVitalsDelta, { currentVitals: props.currentState.vitals, stateVitals: props.progressionState.medicalState.vitals }), _jsx(MedicalDeltaDisplay, { currentState: props.currentState, futureState: props.progressionState.medicalState }), _jsx("div", __assign({ className: "text-gray-500 text-sm" }, { children: props.progressionState.description })), props.onStartTransition &&
                    _jsxs("div", __assign({ className: "flex items-end justify-between w-full space-x-2" }, { children: [_jsxs("div", { children: [_jsx("span", __assign({ className: "text-xs" }, { children: "Duration" })), _jsx(DraggableNumericInput, { value: transitionTimeMinutes, onChange: setTransitionTimeMinutes, unit: "minutes", originalValue: transitionTimeMinutes, min: 0, max: 15 })] }), _jsx("button", __assign({ className: buttonStyles, onClick: startTransition }, { children: getDisplayTime() }))] }))] })) }), props.key));
};
export var StateProgressionVerticalTimeline = function (_a) {
    var initialState = _a.initialState, states = _a.states, onClose = _a.onClose;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var setTargetState = useStateSelectHook().setTargetState;
    var setIsPlaying = useDynamicVirtualPatient.getState().setIsPlaying;
    var initialStateData = {
        shortName: "Initial Presentation",
        defaultDurationMinutes: 3,
        medicalState: initialState,
        description: "The initial presentation of the patient"
    };
    var startTransition = function (progressionState, durationInMinutes) {
        var fullTargetMedicalState = __assign(__assign({}, virtualPatientState.medicalState), progressionState.medicalState);
        setTargetState(fullTargetMedicalState, progressionState.shortName, durationInMinutes * 60);
        setTimeout(function () {
            setIsPlaying(true);
        }, 1000);
        onClose();
    };
    return (_jsxs(VerticalTimelineLayout, { children: [_jsx(ConfigurableTimelineItem, { label: "Initial Presentation", currentState: virtualPatientState.medicalState, progressionState: initialStateData }, "root"), states.map(function (state, index) { return (_jsx(ConfigurableTimelineItem, { label: state.data.shortName, currentState: virtualPatientState.medicalState, progressionState: state.data, onStartTransition: startTransition }, state.id)); })] }));
};
