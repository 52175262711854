var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import ArcwareView from '../../components/Arcware/Arcware';
import { QRCodeSVG } from 'qrcode.react';
import { SessionProvider, useSession } from '../Sessions/SessionContext';
import { useVirtualPatientStore } from '../../storage/VirtualPatientStore';
import { useAppContext } from '../../AppContext';
import { UE5MessageType } from '../../components/ue5';
import { PARTY_MODE_CONTROLLER_ROUTE } from './VPCastRoutes';
var VPCastView = function () {
    var _a = useSession(), fullSessionData = _a.rawSessionData, sessionId = _a.sessionId, createSession = _a.createSession, onMessageReceived = _a.subscribeToMessages;
    var setVirtualPatientState = useVirtualPatientStore().setVirtualPatientState;
    var broadcastMessage = useAppContext().broadcastMessage;
    var _b = React.useState(''), sessionControllerURL = _b[0], setSessionControllerURL = _b[1];
    useEffect(function () {
        createSession();
    }, [createSession]);
    useEffect(function () {
        if (sessionId) {
            setSessionControllerURL("".concat(window.location.origin).concat(PARTY_MODE_CONTROLLER_ROUTE, "?sessionId=").concat(sessionId));
        }
    }, [sessionId]);
    useEffect(function () {
        if (!fullSessionData || !fullSessionData.data) {
            return;
        }
        if (fullSessionData.data.virtualPatientState) {
            console.log("Received virtual patient state", fullSessionData.data.virtualPatientState);
            setVirtualPatientState(fullSessionData.data.virtualPatientState);
        }
    }, [fullSessionData, setVirtualPatientState]);
    useEffect(function () {
        var handleMessage = function (message) {
            if (message.type === UE5MessageType.setCamera) {
                var cameraId = message.cameraId;
                var cameraName = ["top", "side", "face"][cameraId];
                console.log("Setting camera to ".concat(cameraName));
                broadcastMessage(UE5MessageType.setCamera, { name: cameraName });
            }
        };
        // Register the callback and get the unregister function
        var unregister = onMessageReceived(handleMessage);
        // Automatically unregister the callback when the component unmounts
        return function () {
            unregister();
        };
    }, [onMessageReceived, broadcastMessage]);
    return (_jsxs("div", __assign({ className: 'w-full h-full' }, { children: [_jsx("div", __assign({ id: "ue5-container", className: "flex-grow h-full relative" }, { children: _jsx(ArcwareView, {}) })), _jsxs("div", __assign({ className: 'absolute left-0 top-0 z-[9999999] justify-end p-2 gap-2 text-white flex flex-col' }, { children: [_jsx("button", __assign({ onClick: function () { return broadcastMessage(UE5MessageType.loadLevel, { name: "ward" }); } }, { children: "Go to ward" })), _jsx("button", __assign({ onClick: function () { return broadcastMessage(UE5MessageType.loadLevel, { name: "emergency" }); } }, { children: "Go to ER" })), sessionId ? (_jsx("a", __assign({ href: sessionControllerURL, target: '_blank', rel: "noreferrer" }, { children: _jsx(QRCodeSVG, { value: sessionControllerURL, includeMargin: true, bgColor: '#ffffff', fgColor: '#000000' }) }))) : (_jsx("div", { children: "Creating session..." }))] }))] })));
};
var VPCastIndex = function () {
    return (_jsx(SessionProvider, { children: _jsx(VPCastView, {}) }));
};
export default VPCastIndex;
