import { useEffect, useState } from "react";
export function formatTime(time) {
    return new Date(time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit"
    });
}
var useTime = function () {
    var _a = useState(function () { return Date.now(); }), currentTime = _a[0], setCurrentTime = _a[1];
    useEffect(function () {
        var interval = setInterval(function () {
            setCurrentTime(Date.now());
        }, 1000); // Update every second
        return function () { return clearInterval(interval); }; // Cleanup on unmount
    }, []);
    var formattedTime = formatTime(currentTime);
    return { epochTime: currentTime, formattedTime: formattedTime };
};
export default useTime;
