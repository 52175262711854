var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as d3 from "d3";
import React, { useEffect, useRef, useCallback } from 'react';
// Custom hook for handling container dimensions
var useContainerDimensions = function () {
    var containerRef = useRef(null);
    var _a = React.useState({ width: 0, height: 0 }), dimensions = _a[0], setDimensions = _a[1];
    useEffect(function () {
        var updateDimensions = function () {
            if (containerRef.current) {
                var _a = containerRef.current.getBoundingClientRect(), width = _a.width, height = _a.height;
                setDimensions({ width: width, height: height });
            }
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return function () { return window.removeEventListener('resize', updateDimensions); };
    }, []);
    return { containerRef: containerRef, dimensions: dimensions };
};
// Custom hook for D3 scales
var useD3Scales = function (dimensions, xAxisRange) {
    return {
        xScale: d3.scaleLinear()
            .domain([0, xAxisRange])
            .range([0, dimensions.width]),
        yScale: d3.scaleLinear()
            .domain([0, 100])
            .range([dimensions.height, 0])
    };
};
// Custom hook for creating the D3 line generator
var useLineGenerator = function (scales) {
    return d3.line()
        .x(function (d) { return scales.xScale(d.x); })
        .y(function (d) { return scales.yScale(d.y); })
        .curve(d3.curveBasis);
};
// Custom hook for animation frame handling
var useAnimationFrame = function (callback, fps) {
    if (fps === void 0) { fps = 20; }
    var frameRef = useRef();
    var lastTimeRef = useRef(0);
    var intervalRef = useRef(1000 / fps);
    useEffect(function () {
        var tick = function (timestamp) {
            var elapsed = timestamp - lastTimeRef.current;
            if (elapsed >= intervalRef.current) {
                callback();
                lastTimeRef.current = timestamp - (elapsed % intervalRef.current);
            }
            frameRef.current = requestAnimationFrame(tick);
        };
        frameRef.current = requestAnimationFrame(tick);
        return function () {
            if (frameRef.current) {
                cancelAnimationFrame(frameRef.current);
            }
        };
    }, [callback, fps]);
};
function wrapSlice(arr, start, end) {
    var len = arr.length;
    if (len === 0)
        return []; // Handle empty array
    // Normalize indices in case they are out of bounds or negative
    start = ((start % len) + len) % len;
    end = ((end % len) + len) % len;
    if (end < start) {
        // Wrap around: slice from start to end of arr, then from 0 to end
        return arr.slice(start).concat(arr.slice(0, end));
    }
    else {
        // Normal slice within bounds
        return arr.slice(start, end);
    }
}
var sampleWaveData = function (waveData, numBeatsInCurve, bpm, sampleTime, drawTime, duration, randomness) {
    if (randomness === void 0) { randomness = 0; }
    var samplesPerSecondInWaveData = waveData.length / numBeatsInCurve;
    var numSamples = waveData.length;
    var timeScale = bpm / 60;
    var sampleStartTime = sampleTime;
    var sampleStartIndex = Math.round((sampleStartTime % numBeatsInCurve) * samplesPerSecondInWaveData) % numSamples;
    var sampleEndTime = sampleStartTime + (duration * timeScale);
    var sampleEndIndex = Math.round((sampleEndTime % numBeatsInCurve) * samplesPerSecondInWaveData) % numSamples;
    var sampledWaveData = wrapSlice(waveData, sampleStartIndex, sampleEndIndex);
    var max = 1;
    var min = max - randomness;
    var r = (Math.random() * (max - min) + min);
    var samples = sampledWaveData.map(function (d, i) { return ({
        x: drawTime + (i / (samplesPerSecondInWaveData * timeScale)),
        y: d.y * r,
    }); });
    return { samples: samples, newSampleTime: sampleEndTime };
};
export var AnimWave = function (_a) {
    var waveData = _a.waveData, bpm = _a.bpm, color = _a.color, _b = _a.numBeatsInCurve, numBeatsInCurve = _b === void 0 ? 10 : _b, _c = _a.displaySeconds, displaySeconds = _c === void 0 ? 5 : _c, id = _a.id, // Generate unique ID if not provided
    _d = _a.randomness, // Generate unique ID if not provided
    randomness = _d === void 0 ? 0 : _d;
    var _e = useContainerDimensions(), containerRef = _e.containerRef, dimensions = _e.dimensions;
    var svgRef = useRef(null);
    var scales = useD3Scales(dimensions, displaySeconds);
    var lineGenerator = useLineGenerator(scales);
    var dataBufferRef = useRef([]);
    var prevUpdateTimeRef = useRef(0);
    var drawTimeRef = useRef(0);
    var sampleTimeRef = useRef(0);
    // Create unique IDs for clipPath elements
    var clipPathId = "wave-clip-".concat(id);
    var leftRectId = "left-rect-".concat(id);
    var rightRectId = "right-rect-".concat(id);
    // Setup SVG, clipPath and path
    useEffect(function () {
        if (!svgRef.current || !dimensions.width)
            return;
        var svg = d3.select(svgRef.current)
            .style('overflow', 'hidden')
            .style('background', 'transparent')
            // .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)
            .attr('preserveAspectRatio', 'none')
            .classed('h-full w-full', true)
            // .attr('width', dimensions.width)
            .attr('height', dimensions.height);
        svg.selectAll('*').remove();
        // Define the clipPath with unique ID
        var defs = svg.append('defs');
        var clipPath = defs.append('clipPath')
            .attr('id', clipPathId);
        // Create rectangles with unique IDs
        clipPath.append('rect')
            .attr('id', leftRectId)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', '100%')
            .attr('height', '100%');
        clipPath.append('rect')
            .attr('id', rightRectId)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 0)
            .attr('height', '100%');
        // Add the main path with the unique clip-path reference
        svg.append('path')
            .datum(dataBufferRef.current)
            .attr('clip-path', "url(#".concat(clipPathId, ")"))
            .attr('fill', 'none')
            .attr('stroke', color)
            .attr('stroke-width', 2);
    }, [dimensions, color, clipPathId, leftRectId, rightRectId]);
    // Animation update function
    var updateWave = useCallback(function () {
        if (!svgRef.current || !dimensions.width)
            return;
        var currentTime = Date.now();
        var timeDeltaSeconds = (currentTime - prevUpdateTimeRef.current) / 1000;
        // Update data buffer
        var dataToLeftOfTimeline = dataBufferRef.current.filter(function (d) { return d.x < drawTimeRef.current; });
        var dataToRightOfTimeline = dataBufferRef.current.filter(function (d) { return d.x >= drawTimeRef.current + timeDeltaSeconds; });
        if (drawTimeRef.current + timeDeltaSeconds >= displaySeconds) {
            var wrapAroundTime_1 = drawTimeRef.current + timeDeltaSeconds - displaySeconds;
            dataToLeftOfTimeline = dataToLeftOfTimeline.filter(function (d) { return d.x >= wrapAroundTime_1; });
        }
        var _a = sampleWaveData(waveData, numBeatsInCurve, bpm, sampleTimeRef.current, drawTimeRef.current, timeDeltaSeconds, randomness), samples = _a.samples, newSampleTime = _a.newSampleTime;
        sampleTimeRef.current = newSampleTime;
        var dataToInsert = __spreadArray([], samples, true).filter(function (d) { var _a; return d.y !== ((_a = dataToLeftOfTimeline[dataToLeftOfTimeline.length - 1]) === null || _a === void 0 ? void 0 : _a.y); });
        dataBufferRef.current = __spreadArray(__spreadArray(__spreadArray([], dataToLeftOfTimeline, true), dataToInsert, true), dataToRightOfTimeline, true);
        var hideWidth = scales.xScale(0.1);
        var drawPointX = scales.xScale(drawTimeRef.current);
        // Update clipPath rectangles using unique IDs
        d3.select(svgRef.current)
            .select("#".concat(leftRectId))
            .attr('x', 0)
            .attr('width', Math.max(0, drawPointX - hideWidth));
        d3.select(svgRef.current)
            .select("#".concat(rightRectId))
            .attr('x', drawPointX + hideWidth)
            .attr('width', dimensions.width);
        // Update the path
        d3.select(svgRef.current)
            .select('path')
            .datum(dataBufferRef.current)
            .attr('d', lineGenerator);
        prevUpdateTimeRef.current = currentTime;
        drawTimeRef.current = (drawTimeRef.current + timeDeltaSeconds) % displaySeconds;
    }, [dimensions, lineGenerator, displaySeconds, scales, waveData, numBeatsInCurve, bpm, leftRectId, rightRectId, randomness]);
    // Start animation
    useAnimationFrame(updateWave);
    return (_jsx("div", __assign({ ref: containerRef, className: "h-full w-full relative" }, { children: _jsx("svg", { ref: svgRef, className: "absolute top-0 left-0" }) })));
};
