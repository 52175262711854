var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import VitalsSection from "./StateRenderers/VitalsSection";
import { IoMenu } from "react-icons/io5";
import InfoSection from "./InfoSection";
import DevicesSection from "./StateRenderers/DevicesSection";
import SettingsModal from "./SettingsModal";
import { SessionProvider, useSession } from "../Sessions/SessionContext";
import Lottie from "lottie-react";
import loadingAnimation from "./loadingAnimation.json";
import pulseAnimation from "./pulseAnimation2.json";
import StateSelectModal from "./Progression/StateSelectModal";
import LoadPatientModal from "./LoadPatientModal";
import ProgressionControls from "./Progression/StateProgression";
import { Toaster } from "react-hot-toast";
import { CinemedicSupervisorDataProvider, useCinemedicSupervisorDataContext } from "./Data/CinemedicSupervisorDataContext";
import CustomStateModal from "./Progression/CustomStateModal";
import SpeechControlSection from "./StateRenderers/SpeechControlSection";
import HybridControlSection from "./StateRenderers/HybridSection";
import SkinSection from "./StateRenderers/SkinSection";
var SupervisorApp = function () {
    var sessionId = useSession().sessionId;
    var _a = useCinemedicSupervisorDataContext(), isReady = _a.isReady, canReset = _a.canReset;
    var _b = React.useState(false), isSettingsModalOpen = _b[0], setIsSettingsModalOpen = _b[1];
    var _c = React.useState(false), isStateSelectModalOpen = _c[0], setIsStateSelectModalOpen = _c[1];
    var _d = React.useState(false), isLoadPatientModalOpen = _d[0], setIsLoadPatientModalOpen = _d[1];
    var _e = React.useState(false), isCustomStateModalOpen = _e[0], setIsCustomStateModalOpen = _e[1];
    var toggleSettingsModal = function () {
        setIsSettingsModalOpen(!isSettingsModalOpen);
    };
    var toggleStateSelectModal = function () {
        setIsStateSelectModalOpen(!isStateSelectModalOpen);
    };
    var toggleLoadPatientModal = function () {
        setIsLoadPatientModalOpen(!isLoadPatientModalOpen);
    };
    var toggleCustomStateModal = function () {
        setIsCustomStateModalOpen(!isCustomStateModalOpen);
    };
    var loadingContainerVariants = {
        visible: {
            opacity: 1,
            transition: { duration: 0.5 }
        },
        exit: {
            opacity: 0,
            scale: 1.05,
            transition: { duration: 1, delay: 0 }
        },
    };
    return (_jsxs(AnimatePresence, { children: [!isReady && (_jsx(motion.div, __assign({ className: "h-full w-full bg-white flex items-center justify-center z-50 fixed origin-center", variants: loadingContainerVariants, initial: "visible", animate: "visible", exit: "exit" }, { children: _jsxs("div", __assign({ className: "flex flex-col items-center space-y-4" }, { children: [_jsx(Lottie, { animationData: loadingAnimation, loop: true, className: "w-32 h-32" }), _jsx("div", __assign({ className: "text-gray-800 text-center" }, { children: sessionId ?
                                _jsxs("div", { children: ["Connecting to Cinemedic Pro...", _jsx("div", __assign({ className: "mt-8 bg-gray-200 p-4 rounded-full shadow" }, { children: sessionId }))] })
                                :
                                    _jsx("div", { children: "\"No Session Selected\"" }) }))] })) }), "loading screen")), _jsxs("div", __assign({ className: "flex flex-col h-full w-full fixed bg-gray-50 relative" }, { children: [_jsxs("header", __assign({ className: "bg-white shadow-[0px_0px_5px_0px_rgba(0,_0,_0,_0.3)] w-full p-2 flex items-center justify-between z-10" }, { children: [_jsxs("h1", __assign({ className: "text-base font-semibold text-gray-800 flex items-center" }, { children: [_jsx(Lottie, { animationData: pulseAnimation, loop: true, className: "h-8 w-8" }), "Cinemedic Pro: ", sessionId] })), _jsx("div", __assign({ className: "rounded-full shadow border px-2 py-2 active:shadow-inner cursor-pointer select-none", onClick: toggleSettingsModal }, { children: _jsx("span", __assign({ className: "text-xl text-gray-500" }, { children: _jsx(IoMenu, {}) })) }))] })), _jsxs("main", __assign({ className: " overflow-auto relative p-4 space-y-4" }, { children: [_jsx(InfoSection, {}), _jsx(VitalsSection, { onShowCustomStateModal: toggleCustomStateModal }), _jsx(DevicesSection, {}), _jsx(HybridControlSection, {}), _jsx(SpeechControlSection, {}), _jsx(SkinSection, {})] }), "content"), _jsx("div", __assign({ className: "h-16" }, { children: _jsx(ProgressionControls, { onOpenStateSelectModal: toggleStateSelectModal, onOpenCustomStateModal: toggleCustomStateModal }) }))] }), "main-container"), _jsx(SettingsModal, { isOpen: isSettingsModalOpen, onClose: toggleSettingsModal, onShowLoadPatient: toggleLoadPatientModal, canReset: canReset }, "settings"), _jsx(StateSelectModal, { isOpen: isStateSelectModalOpen, onClose: toggleStateSelectModal }, "state-select"), _jsx(LoadPatientModal, { isOpen: isLoadPatientModalOpen, onClose: toggleLoadPatientModal }, "load-patient"), _jsx(CustomStateModal, { isOpen: isCustomStateModalOpen, onClose: toggleCustomStateModal }, "custom-state"), _jsx(Toaster, { toastOptions: {
                    className: "bg-gray-800 text-white rounded shadow-lg",
                } })] }));
};
var SupervisorIndex = function () {
    return (_jsx(SessionProvider, { children: _jsx(CinemedicSupervisorDataProvider, { children: _jsx(SupervisorApp, {}) }) }));
};
export default SupervisorIndex;
