var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext } from "react";
import { useSupervisorDataHook_Private } from "./useCinemedicSupervisorHooks";
// Create the context
var CinemedicSupervisorDataContext = createContext(undefined);
// Create the provider
export var CinemedicSupervisorDataProvider = function (_a) {
    var children = _a.children;
    var supervisorData = useSupervisorDataHook_Private();
    return (_jsx(CinemedicSupervisorDataContext.Provider, __assign({ value: supervisorData }, { children: children })));
};
export var useCinemedicSupervisorDataContext = function () {
    var context = useContext(CinemedicSupervisorDataContext);
    if (!context) {
        throw new Error("useSupervisorData must be used within a SupervisorDataProvider");
    }
    return context;
};
