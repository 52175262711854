var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FiBookmark } from "react-icons/fi";
import VirtualPatientStateStorage from "../../components/Database/VirtualPatientStateStorage";
import { useCinemedicSupervisorDataContext } from "./Data/CinemedicSupervisorDataContext";
var buttonStyles = "w-full px-4 py-2 rounded-lg active:shadow-inner border flex items-center justify-center gap-2 space-x-2 text-sm bg-gray-50";
var chipStyles = "rounded-full bg-slate-100 px-2 py-1";
var PatientStateCard = function (_a) {
    var _b;
    var state = _a.state, onSelectedForLoad = _a.onSelectedForLoad;
    return (_jsxs("section", __assign({ className: "bg-white shadow-sm rounded-lg border p-4 space-y-2" }, { children: [_jsx("div", __assign({ className: "flex items-center justify-between cursor-pointer" }, { children: _jsxs("div", __assign({ className: "flex items-center gap-2 " }, { children: [_jsx(FiBookmark, {}), " ", _jsx("h3", { children: state.virtualPatientState.name })] })) })), _jsxs("div", __assign({ className: "text-gray-500 text-sm flex w-full space-x-2" }, { children: [_jsx("span", __assign({ className: chipStyles }, { children: state.virtualPatientState.staticData.patientProfile.age })), _jsx("span", __assign({ className: chipStyles }, { children: state.virtualPatientState.staticData.patientProfile.sex })), _jsxs("span", __assign({ className: chipStyles }, { children: [state.virtualPatientState.staticData.patientProfile.weight, " kg"] }))] })), _jsx("div", __assign({ className: "text-sm text-gray-500" }, { children: (_b = state.caseDescription) === null || _b === void 0 ? void 0 : _b.description })), _jsx("div", __assign({ className: "grid grid-cols-2 gap-2" }, { children: _jsx("button", __assign({ className: "".concat(buttonStyles, " bg-slate-700 text-gray-50"), onClick: function () { onSelectedForLoad(state); } }, { children: _jsx("span", { children: "Load" }) })) }))] })));
};
var SkeletonCard = function () {
    return (_jsxs("section", __assign({ className: "bg-gray-200 shadow-sm rounded-lg border p-4 animate-pulse space-y-2" }, { children: [_jsx("div", __assign({ className: "flex items-center justify-between w-full" }, { children: _jsxs("div", __assign({ className: "flex items-center gap-2 w-full" }, { children: [_jsx("div", __assign({ className: "bg-gray-300 rounded-full h-5 w-5" }, { children: "\u00A0" })), _jsx("div", __assign({ className: "bg-gray-300 w-full rounded" }, { children: "\u00A0" }))] })) })), _jsxs("div", __assign({ className: "text-gray-500 text-sm flex w-full space-x-2" }, { children: [_jsx("span", __assign({ className: "rounded-full bg-gray-300 px-2 py-1 w-48" }, { children: "\u00A0" })), _jsx("span", __assign({ className: "rounded-full bg-gray-300 px-2 py-1 w-24" }, { children: "\u00A0" }))] })), _jsxs("div", __assign({ className: "text-sm bg-gray-300 rounded w-full mb-4" }, { children: ["\u00A0", _jsx("br", {}), "\u00A0"] })), _jsx("div", __assign({ className: "grid grid-cols-2 gap-2" }, { children: _jsx("div", { className: "bg-gray-300 h-8 w-full rounded" }) }))] })));
};
var LoadPatientModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    var _b = React.useState([]), presets = _b[0], setPresets = _b[1];
    var _c = React.useState(true), loading = _c[0], setLoading = _c[1];
    var loadNewPatient = useCinemedicSupervisorDataContext().loadNewPatient;
    React.useEffect(function () {
        setLoading(true);
        VirtualPatientStateStorage.listVirtualPatientStates().then(function (presets) {
            setPresets(presets);
            setLoading(false);
        });
    }, [isOpen]);
    var loadState = React.useCallback(function (state) {
        loadNewPatient(state);
        onClose();
    }, [loadNewPatient, onClose]);
    return (_jsx(AnimatePresence, { children: isOpen && (_jsxs(Dialog, __assign({ open: isOpen, onClose: onClose, className: "relative z-50" }, { children: [isOpen && (_jsx(motion.div, { className: "fixed inset-0 bg-black bg-opacity-80", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 }, onClick: onClose }, "background-overlay")), _jsx("div", __assign({ className: "fixed inset-0 flex items-center justify-center" }, { children: _jsxs(motion.div, __assign({ className: "bg-gray-50 p-6 w-full h-full xs:max-w-md max-w-xl flex flex-col space-y-4", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsx("div", __assign({ className: "flex items-center justify-around w-full" }, { children: _jsx("h1", __assign({ className: "text-base font-semibold text-gray-800" }, { children: "Load Virtual Patient" })) })), _jsx("div", __assign({ className: "overflow-y-auto space-y-4 flex-grow" }, { children: loading ? (_jsxs("div", __assign({ className: "h-full overflow-y-hidden space-y-4 relative" }, { children: [_jsx("div", { className: "absolute inset-0 pointer-events-none bg-gradient-to-b from-transparent to-gray-50 z-10" }), Array.from({ length: 10 }).map(function (_, index) { return (_jsx(SkeletonCard, {}, index)); })] }))) : (presets.length > 0 ? (presets.map(function (preset) { return (_jsx(PatientStateCard, { state: preset, onSelectedForLoad: loadState }, preset.id)); })) : (_jsx("div", __assign({ className: "text-center text-gray-500" }, { children: "No virtual patients found." })))) })), _jsx("div", __assign({ className: "" }, { children: _jsx("button", __assign({ onClick: onClose, className: "".concat(buttonStyles, " bg-slate-700 text-gray-50 w-auto") }, { children: "Close" })) }))] }), "modal") }))] }))) }));
};
export default LoadPatientModal;
