import { VirtualPatientStateSchema } from '../PatientState';
import { z } from 'zod';
// Define the PatientPresetMetadata schema
var PatientPresetMetadataSchema = z.object({
    ownerId: z.string(),
    ownerName: z.string(),
    email: z.string().nullable(),
    createdAt: z.date(),
    updatedAt: z.date(),
});
// Define the Metadata function schema
export var Metadata = function (userUid, userName, email) {
    var timestamp = new Date();
    return PatientPresetMetadataSchema.parse({
        ownerId: userUid,
        ownerName: userName,
        email: email,
        createdAt: timestamp,
        updatedAt: timestamp,
    });
};
// Define the PresetHeirarchyData schema
var PresetHeirarchyDataSchema = z.object({
    parentId: z.string().nullable(),
    children: z.array(z.string()),
    entranceAction: z.string().optional(),
});
// Define the ProgressionData schema
var ProgressionDataSchema = z.object({
    nextStateId: z.string(),
    cause: z.string().nullable(),
    timeInMinutes: z.number(),
});
var CaseDescriptionSchema = z.object({
    description: z.string(),
    docURL: z.string().optional(),
});
// Define the FirestoreDoc_VirtualPatientState schema
export var FirestoreDoc_VirtualPatientStateSchema = z.object({
    id: z.string().optional(),
    virtualPatientState: VirtualPatientStateSchema,
    metadata: PatientPresetMetadataSchema,
    heirarchy: PresetHeirarchyDataSchema.optional(),
    progression: ProgressionDataSchema.optional(),
    caseDescription: CaseDescriptionSchema.optional(),
    defaultProgressionSequence: z.array(z.string()).optional(),
});
