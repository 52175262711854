var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
import { produce } from 'immer';
export var MonitorSection;
(function (MonitorSection) {
    MonitorSection["HR"] = "hr";
    MonitorSection["RR"] = "rr";
    MonitorSection["SPO2"] = "spo2";
})(MonitorSection || (MonitorSection = {}));
export var ConnectionType;
(function (ConnectionType) {
    ConnectionType["ecg"] = "ecg";
    ConnectionType["spo2"] = "spo2";
})(ConnectionType || (ConnectionType = {}));
var DefaultMonitorSettings = {
    hr: {
        thresholds: {
            min: 50,
            max: 130,
        },
        muteNormal: false,
    },
    rr: {
        thresholds: {
            min: 8,
            max: 30,
        },
    },
    spo2: {
        thresholds: {
            min: 92,
            max: 100,
        },
    },
};
export var useMonitorSettingsStore = create(function (set) { return ({
    monitorSettings: DefaultMonitorSettings,
    alarmsMutedFor: [],
    connectionAlertsPrimedFor: { ecg: false, spo2: false },
    updateHRSettings: function (settings) {
        return set(produce(function (state) {
            state.monitorSettings.hr = __assign(__assign({}, state.monitorSettings.hr), settings);
        }));
    },
    updateRRSettings: function (settings) {
        return set(produce(function (state) {
            state.monitorSettings.rr = __assign(__assign({}, state.monitorSettings.rr), settings);
        }));
    },
    updateSpo2Settings: function (settings) {
        return set(produce(function (state) {
            state.monitorSettings.spo2 = __assign(__assign({}, state.monitorSettings.spo2), settings);
        }));
    },
    setMutedAlarms: function (sections) {
        return set(produce(function (state) {
            state.alarmsMutedFor = sections;
        }));
    },
    setConnectionAlertsPrimedFor: function (sections) {
        return set(produce(function (state) {
            state.connectionAlertsPrimedFor = sections;
        }));
    },
}); });
