import * as React from "react";
import { useAudioManager } from "./useAudioManager";
export function useBeepLoop(isActive, config) {
    var playSingleTone = useAudioManager().playSingleTone;
    // Refs to hold the "live" values that can change over time
    var activeRef = React.useRef(isActive);
    var configRef = React.useRef(config);
    // Keep the refs updated as props change
    React.useEffect(function () {
        activeRef.current = isActive;
    }, [isActive]);
    React.useEffect(function () {
        configRef.current = config;
    }, [config]);
    // Set up one persistent loop
    React.useEffect(function () {
        var keepLooping = true; // used for cleanup
        function loop() {
            // If not active, skip beeping, but keep checking regularly
            if (!activeRef.current) {
                setTimeout(function () {
                    if (keepLooping)
                        loop();
                }, 500);
                return;
            }
            // Play the beep with the latest config
            var _a = configRef.current, frequency = _a.frequency, duration = _a.duration, _b = _a.type, type = _b === void 0 ? "sine" : _b, _c = _a.volume, volume = _c === void 0 ? 1 : _c, getInterval = _a.getInterval;
            playSingleTone(frequency, duration, type, volume);
            // Schedule the next beep after the interval
            var delay = getInterval(); // e.g. depends on dynamic heart rate
            setTimeout(function () {
                if (keepLooping)
                    loop();
            }, delay);
        }
        loop(); // start immediately
        // Cleanup when unmounting or re-running this effect (rare if no deps)
        return function () {
            keepLooping = false;
        };
    }, [playSingleTone]);
}
