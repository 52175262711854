/**
 * Plays a single tone (with a quick fade-in/out) using the provided AudioContext.
 */
export function playTone(audioCtx, frequency, durationMs, type, volume) {
    if (type === void 0) { type = 'sine'; }
    if (volume === void 0) { volume = 0.2; }
    var oscillator = audioCtx.createOscillator();
    var gainNode = audioCtx.createGain();
    oscillator.type = type;
    oscillator.frequency.value = frequency;
    var now = audioCtx.currentTime;
    var durationSec = durationMs / 1000;
    // Simple envelope to avoid clicks
    gainNode.gain.setValueAtTime(0, now);
    gainNode.gain.linearRampToValueAtTime(volume, now + 0.01);
    gainNode.gain.setValueAtTime(volume, now + durationSec - 0.01);
    gainNode.gain.linearRampToValueAtTime(0, now + durationSec);
    oscillator.connect(gainNode);
    gainNode.connect(audioCtx.destination);
    oscillator.start(now);
    oscillator.stop(now + durationSec);
}
/**
 * Plays a sequence of repeated beeps (frequency, beep duration, pause, etc.)
 */
export function playRepeatedTone(audioCtx, frequency, beepDurationMs, pauseDurationMs, repeatCount, type, volume) {
    if (type === void 0) { type = 'sine'; }
    if (volume === void 0) { volume = 0.2; }
    var now = audioCtx.currentTime;
    for (var i = 0; i < repeatCount; i++) {
        var startTime = now + (i * (beepDurationMs + pauseDurationMs)) / 1000;
        var endTime = startTime + beepDurationMs / 1000;
        var oscillator = audioCtx.createOscillator();
        var gainNode = audioCtx.createGain();
        oscillator.type = type;
        oscillator.frequency.value = frequency;
        // Envelope
        gainNode.gain.setValueAtTime(0, startTime);
        gainNode.gain.linearRampToValueAtTime(volume, startTime + 0.01);
        gainNode.gain.setValueAtTime(volume, endTime - 0.01);
        gainNode.gain.linearRampToValueAtTime(0, endTime);
        oscillator.connect(gainNode);
        gainNode.connect(audioCtx.destination);
        oscillator.start(startTime);
        oscillator.stop(endTime);
    }
}
