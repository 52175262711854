var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var formatPercentage = function (value) {
    return "".concat(Math.round(value * 100), "%");
};
var PercentageValue = function (_a) {
    var label = _a.label, currentValue = _a.currentValue, targetValue = _a.targetValue, unit = _a.unit;
    var showTarget = targetValue !== undefined && targetValue !== currentValue;
    if (currentValue === 0 && !showTarget) {
        return null;
    }
    return (_jsxs("div", __assign({ className: "flex flex-col justify-start" }, { children: [_jsx("p", __assign({ className: "text-xs md:text-sm text-gray-500" }, { children: label })), _jsxs("p", __assign({ className: "text-base md:text-xl font-medium text-gray-700" }, { children: [formatPercentage(currentValue), showTarget && (_jsxs("span", __assign({ className: "text-blue-500" }, { children: [" \u2192 ", formatPercentage(targetValue)] }))), unit && _jsxs("span", __assign({ className: "text xs md:text-sm text-gray-500" }, { children: [" ", unit] }))] }))] })));
};
export var GenericValue = function (_a) {
    var label = _a.label, currentValue = _a.currentValue, targetValue = _a.targetValue, unit = _a.unit;
    var showTarget = targetValue !== undefined && targetValue !== currentValue;
    return (_jsxs("div", __assign({ className: "flex flex-col justify-start" }, { children: [_jsx("p", __assign({ className: "text-xs md:text-sm text-gray-500" }, { children: label })), _jsxs("p", __assign({ className: "text-base md:text-xl font-medium text-gray-700" }, { children: [currentValue, showTarget && (_jsxs("span", __assign({ className: "text-blue-500" }, { children: [" \u2192 ", targetValue] }))), unit && _jsxs("span", __assign({ className: "text-xs md:text-sm text-gray-500" }, { children: [" ", unit] }))] }))] })));
};
export default PercentageValue;
