import { useCinemedicSupervisorDataContext } from "../Data/CinemedicSupervisorDataContext";
import React from "react";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
export function useStateSelectHook() {
    var setActiveInterpolationData = useCinemedicSupervisorDataContext().setActiveInterpolationData;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var virtualPatientStateRef = React.useRef(virtualPatientState);
    React.useEffect(function () {
        virtualPatientStateRef.current = virtualPatientState;
    }, [virtualPatientState]);
    var setTargetState = React.useCallback(function (targetState, targetStateName, durationInSeconds) {
        setActiveInterpolationData({
            state0: virtualPatientStateRef.current.medicalState,
            state1: targetState,
            durationInSeconds: durationInSeconds,
            targetStateName: targetStateName,
        });
    }, [setActiveInterpolationData]);
    return {
        setTargetState: setTargetState
    };
}
