var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { GoArrowUpRight, GoArrowDownRight } from "react-icons/go";
export var TimelineVitalsDelta = function (_a) {
    var currentVitals = _a.currentVitals, stateVitals = _a.stateVitals;
    var sectionClasses = "flex flex-col items-start gap-1 text-gray-600";
    var titleClasses = "text-xs opacity-50";
    var contentClasses = "flex text-gray-500";
    var getArrow = function (current, next) {
        var arrowClasses = "w-4 h-4 inline opacity-50";
        if (current === next)
            return null;
        return current < next ?
            _jsx(GoArrowUpRight, { className: arrowClasses })
            :
                _jsx(GoArrowDownRight, { className: arrowClasses });
    };
    return (_jsxs("div", __assign({ className: "grid grid-cols-3 text-md leading-tight gap-2" }, { children: [Math.round(currentVitals.heartRate) !== Math.round(stateVitals.heartRate) && (_jsxs("div", __assign({ className: sectionClasses }, { children: [_jsx("div", __assign({ className: titleClasses }, { children: "HR" })), _jsxs("div", __assign({ className: contentClasses }, { children: [getArrow(currentVitals.heartRate, stateVitals.heartRate), _jsxs("div", { children: [stateVitals.heartRate, " bpm"] })] }))] }))), Math.round(currentVitals.respiratoryRate) !== Math.round(stateVitals.respiratoryRate) && (_jsxs("div", __assign({ className: sectionClasses }, { children: [_jsx("div", __assign({ className: titleClasses }, { children: "RR" })), _jsxs("div", __assign({ className: contentClasses }, { children: [getArrow(currentVitals.respiratoryRate, stateVitals.respiratoryRate), _jsxs("div", { children: [stateVitals.respiratoryRate, " bpm"] })] }))] }))), ((Math.round(currentVitals.nibp.systolic) !== Math.round(stateVitals.nibp.systolic)) ||
                (Math.round(currentVitals.nibp.diastolic) !== Math.round(stateVitals.nibp.diastolic)))
                && (_jsxs("div", __assign({ className: sectionClasses }, { children: [_jsx("div", __assign({ className: titleClasses }, { children: "BP" })), _jsxs("div", __assign({ className: contentClasses }, { children: [getArrow(currentVitals.nibp.systolic, stateVitals.nibp.systolic), " ", stateVitals.nibp.systolic, " ", _jsx("div", { children: "/" }), "  ", getArrow(currentVitals.nibp.systolic, stateVitals.nibp.systolic), " ", stateVitals.nibp.diastolic] }))] }))), Math.round(currentVitals.spo2) !== Math.round(stateVitals.spo2) && (_jsxs("div", __assign({ className: sectionClasses }, { children: [_jsx("div", __assign({ className: titleClasses }, { children: "SPo2" })), _jsxs("div", __assign({ className: contentClasses }, { children: [getArrow(currentVitals.spo2, stateVitals.spo2), _jsxs("div", { children: [stateVitals.spo2, " %"] })] }))] }))), Math.round(currentVitals.etco2) !== Math.round(stateVitals.etco2) && (_jsxs("div", __assign({ className: sectionClasses }, { children: [_jsx("div", __assign({ className: titleClasses }, { children: "EtCo2" })), _jsxs("div", __assign({ className: contentClasses }, { children: [getArrow(currentVitals.etco2, stateVitals.etco2), _jsxs("div", { children: [stateVitals.etco2, " %"] })] }))] })))] })));
};
