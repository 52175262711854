import { z } from "zod";
export var BloodGasSchema = z.object({
    pH: z.number().describe("none"),
    pCO2: z.number().describe("mmHg"),
    PO2: z.number().describe("mmHg"),
    Hb: z.number().describe("g/L"),
    Hct: z.number().describe("%"),
    sO2: z.number().describe("%"),
    Na: z.number().describe("mmol/L"),
    K: z.number().describe("mmol/L"),
    Ca: z.number().describe("mmol/L"),
    Cl: z.number().describe("mmol/L"),
    Glu: z.number().describe("mmol/L"),
    Lac: z.number().describe("mmol/L"),
    HCO3: z.number().describe("mmol/L"),
    BE: z.number().describe("mmol/L"),
});
