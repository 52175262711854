var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Dialog, Description, DialogTitle } from "@headlessui/react";
import { LuRefreshCcw } from "react-icons/lu";
import { TbHeartRateMonitor } from "react-icons/tb";
import { LuUpload } from "react-icons/lu";
import { AnimatePresence, motion } from "framer-motion";
import { useSession } from "../Sessions/SessionContext";
import { useCinemedicSupervisorDataContext } from "./Data/CinemedicSupervisorDataContext";
var buttonStyles = "w-full px-4 py-2 shadow rounded-lg active:shadow-inner border flex items-center justify-center gap-2 space-x-2\ndisabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none";
var MONITOR_URL = "/vpcast/monitor";
var SettingsModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onShowLoadPatient = _a.onShowLoadPatient, canReset = _a.canReset;
    var sessionId = useSession().sessionId;
    var resetPatient = useCinemedicSupervisorDataContext().resetPatient;
    function openMonitor() {
        window.open("".concat(MONITOR_URL, "?sessionId=").concat(sessionId), "_blank");
        onClose();
    }
    function onResetPatient() {
        var confirmation = window.confirm("Are you sure you want to reset the patient? This action cannot be undone.");
        if (confirmation) {
            // Logic to reset the patient goes here
            // console.log("Patient reset!");
            resetPatient();
            onClose();
        }
        else {
            console.log("Reset cancelled.");
        }
    }
    function loadNewPatient() {
        onShowLoadPatient();
        onClose();
    }
    return (_jsx(AnimatePresence, { children: isOpen && (_jsxs(Dialog, __assign({ open: isOpen, onClose: onClose, className: "relative z-50" }, { children: [isOpen && (_jsx(motion.div, { className: "fixed inset-0 bg-black bg-opacity-30", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 }, onClick: onClose }, "background-overlay")), _jsx("div", __assign({ className: "fixed inset-0 flex items-center justify-center p-4 pointer-events-none" }, { children: _jsxs(motion.div, __assign({ className: "w-full max-w-md bg-white rounded-lg shadow p-6 pointer-events-auto", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.3 } }, { children: [_jsx(DialogTitle, __assign({ className: "text-lg font-medium text-gray-900" }, { children: "Menu Options" })), _jsx(Description, __assign({ className: "mt-2 text-sm text-gray-500" }, { children: "Select an option from the menu." })), _jsxs("div", __assign({ className: "mt-4 space-y-2" }, { children: [_jsxs("button", __assign({ onClick: onResetPatient, className: buttonStyles, disabled: !canReset }, { children: [_jsx(LuRefreshCcw, {}), "Reset Patient"] })), _jsxs("button", __assign({ onClick: loadNewPatient, className: buttonStyles }, { children: [_jsx(LuUpload, {}), "Load New Patient"] })), _jsx("div", { children: "\u00A0" }), _jsxs("button", __assign({ onClick: openMonitor, className: buttonStyles }, { children: [_jsx(TbHeartRateMonitor, {}), "Open Monitor"] }))] })), _jsx("div", __assign({ className: "mt-8" }, { children: _jsx("button", __assign({ onClick: onClose, className: buttonStyles }, { children: "Close" })) }))] }), "modal") }))] }))) }));
};
export default SettingsModal;
