import { z } from "zod";
export var LungSoundEnum = z.enum([
    "Normal",
    "Bronchial",
    "Bronchovesicular",
    "CoarseCrackles",
    "FineCrackles",
    "LowPitchWheeze",
    "PleuralRubs",
    "Wheeze",
]);
export var BreathingSoundSchema = z.object({
    normal: z.number().describe("intensity, 0 - 1"),
    stridor: z.number().describe("intensity, 0 - 1"),
    wheezing: z.number().describe("intensity, 0 - 1"),
    grunting: z.number().describe("intensity, 0 - 1"),
    gurgling: z.number().describe("intensity, 0 - 1"),
    snoring: z.number().describe("intensity, 0 - 1"),
    leftLungSound: LungSoundEnum,
    rightLungSound: LungSoundEnum,
});
export var WorkOfBreathingSchema = z.object({
    sterno: z.number().describe("intensity, 0 - 1"),
    retractions: z.number().describe("intensity, 0 - 1"),
    nasalFlaring: z.number().describe("intensity, 0 - 1"),
    headBobbing: z.number().describe("intensity, 0 - 1"),
});
export var LungSound = LungSoundEnum.enum;
