var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Monitor from "../../components/Monitor/Monitor";
import { SessionProvider, useSession } from "../Sessions/SessionContext";
import { AppProvider } from "../../AppContext";
import { useEffect } from "react";
import { useVirtualPatientStore } from "../../storage/VirtualPatientStore";
import { MonitorAudioContextProvider } from "../../components/Monitor/MonitorAudio/AudioContextProvider";
import { CinemedicSupervisorDataProvider } from "../Supervisor/Data/CinemedicSupervisorDataContext";
var MonitorView = function () {
    var fullSessionData = useSession().rawSessionData;
    var setVirtualPatientState = useVirtualPatientStore().setVirtualPatientState;
    useEffect(function () {
        if (!fullSessionData || !fullSessionData.data || !fullSessionData.data.virtualPatientState)
            return;
        setVirtualPatientState(JSON.parse(JSON.stringify(fullSessionData.data.virtualPatientState)));
        console.log(fullSessionData.data.virtualPatientState.medicalState.vitals);
    }, [fullSessionData, setVirtualPatientState]);
    return (_jsx("div", __assign({ className: "bg-gray-900 w-full h-full" }, { children: _jsx(Monitor, {}) })));
};
var VPCastMonitor = function () {
    return (_jsx(SessionProvider, { children: _jsx(AppProvider, { children: _jsx(CinemedicSupervisorDataProvider, { children: _jsx(MonitorAudioContextProvider, { children: _jsx(MonitorView, {}) }) }) }) }));
};
export default VPCastMonitor;
